export const BRAND_GREEN = "#33B082";
export const BRAND_GREEN_LIGHT = "#28DEAD";
export const BRAND_GREEN_ALT = "#4CAF50";

export const BRAND_BLUE = "#242659";
export const BRAND_BLUE_LIGHT = "#33367D";
export const BRAND_BLUE_ALT = "#004085";

export const PINK = "#D75893";
export const PINK_LIGHT = "#EE579D";

export const ORANGE = "#F07F38";
export const ORANGE_LIGHT = "#FF9800";
export const ORANGE_DARK = "#F25F4B";

export const VIOLET = "#6E4F9B";
export const VIOLET_LIGHT = "#F3EAF8";
export const VIOLET_ALT = "#AA66CC";

export const WHITE = "#FFFFFF";
export const GREY = "#767676";
export const GREY_LIGHT = "#9E9E9E";
export const GREY_SUPERLIGHT = "#EDF0F2";
export const GREY_ALT = "#C8CBCB";

export const CHARCOAL = "#949494";
export const CHARCOAL_LIGHT = "#495057";
export const CHARCOAL_ALT = "#212529";

export const EXPENSE_GRAPH_COLOURS = [BRAND_GREEN, BRAND_BLUE_LIGHT, PINK_LIGHT, ORANGE_LIGHT, VIOLET_ALT, GREY_ALT];

export const DEFAULT_FONT_FAMILY = '"Inter", "Helvetica Neue", "Arial", sans-serif';
