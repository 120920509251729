import React from "react";
import SVG from "react-inlinesvg";
import { PlatformType } from "../navigation_sidebar_panel/types";
import { User } from "../../../global";
import I18n from "../../../utilities/translations";

export interface iMetaHeaderbarProps {
  currentUser: User;
  currentAdminUserId: string;
  environment?: "development" | "staging" | "uat";
  isImpersonating?: boolean;
  isUnsupportedBrowser?: boolean;
  platform?: PlatformType;
}

const copyToClipboard = (text: string | number) => {
  navigator.clipboard.writeText(text.toString());
};

const MetaHeaderbar = ({
  currentUser,
  currentAdminUserId,
  environment,
  isImpersonating = false,
  isUnsupportedBrowser = false,
  platform,
}: iMetaHeaderbarProps) => (
  <>
    {isUnsupportedBrowser && (
      <section className="tw-bg-brand tw-text-white tw-p-4 tw-text-center hui-unsupported-banner">
        {I18n.t("global.unsupported.message")}&nbsp;
        <a
          className="hui-link hui-link--inverted"
          href="https://help.hnry.io/en/articles/2577829-supported-browsers-devices"
          target="_blank"
          rel="noreferrer"
        >
          Find out more.
        </a>
      </section>
    )}
    {isImpersonating && (
      <>
        <div className="tw-flex tw-flex-col sm:tw-flex-row">
          {environment && (
            <div
              className={`${/development/i.test(environment)
                  ? "tw-bg-brand-yellow-500"
                  : "tw-bg-brand-orange-500"
                } tw-p-4 tw-text-black tw-font-semibold tw-text-center`}
            >
              {environment}
              {platform && ` (${platform})`}
            </div>
          )}
          <div className="tw-bg-admin-600 tw-text-white tw-flex tw-p-4 tw-gap-4 tw-justify-end tw-items-start tw-grow tw-font-light">
            <SVG
              src={currentUser.jurisdiction.iconUrl}
              title={`${currentUser.jurisdiction.code.toUpperCase()} jurisdiction`}
              className="tw-mr-auto"
            />

            <span
              id="impersonate-details"
              data-impersonating-id={currentUser.id}
              data-impersonator-id={currentAdminUserId}
            ></span>
            <button
              title="Copy User ID to clipboard"
              onClick={() => copyToClipboard(currentUser.id)}
            >
              {currentUser.name.full}
            </button>
            <button
              className="tw-hidden md:tw-inline-block"
              title="Copy Tax Identification Number to clipboard"
              onClick={() => copyToClipboard(currentUser.tin)}
            >
              {currentUser.tin}
            </button>

            <span className={`hnry-badge ${currentUser.status.colour}`}>
              {currentUser.status.name}
            </span>
            <a
              className="hui-link hui-link--inverted"
              href={Routes.admin_user_path(
                currentUser.jurisdiction.code,
                currentUser.id,
              )}
            >
              View profile
            </a>
            <a
              className="hui-link hui-link--inverted tw-hidden md:tw-inline-block"
              href={Routes.stop_impersonating_admin_user_path(
                currentUser.jurisdiction.code,
                currentUser.id,
              )}
            >
              Stop impersonating
            </a>
          </div>
        </div>
        <div
          id="expire-impersonate"
          className="hidden tw-bg-orange-600 tw-text-white tw-p-4 tw-text-center"
        >
          <p className="tw-m-0">
            This impersonation session is no longer valid and you should{" "}
            <em>reload</em> or <em>close</em> this window.
          </p>
        </div>
      </>
    )}
  </>
);

export default MetaHeaderbar;
