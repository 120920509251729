import React, { useState, useEffect } from "react";
import Icon from "../../_atoms/icons/icon/Icon";
import "./styles.scss";

interface iAlert {
  /**
   * The component comes with different background colours and has the following options:
   */
  variant?: "success" | "danger" | "info" | "warning";
  /**
   * The content of the alert
   */
  children: React.ReactNode | string;
  /**
   * Render the icon on the left? By default, alert will render with icon. To hide icon, includes includesIcon={false} as prop
   */
  includesIcon?: boolean;
  /**
   * Close button, false by default
   */
  dismissible?: boolean;
  /**
   * (Optional) The title of the alert
   */
  title?: string;
  /**
   * Programatically show/hide the alert box
   */
  show?: boolean;
}

const iconType = (variant) => {
  switch (variant) {
    case "success":
      return "CheckCircleIcon";
    case "danger":
      return "XCircleIcon";
    case "warning":
      return "ExclamationTriangleIcon";
    default:
      return "InformationCircleIcon";
  }
};

const Alert = ({
  includesIcon = true,
  variant = "info",
  children,
  dismissible = false,
  title = "",
  show = true,
}: iAlert) => {
  const [showAlert, setShowAlert] = useState(show)

  const hideAlert = () => {
    setShowAlert(false)
  }

  useEffect(
    () => {
      setShowAlert(show)
    }, [show]
  )

  return (
    <>
      {showAlert ? (<div className={`hui-alert hui-alert--${variant}`} role="alert" >
        {dismissible ? (<span className="tw-float-right tw-cursor-pointer" onClick={hideAlert} aria-hidden="true" >
          <Icon type="XMarkIcon" />
        </span >) : null}
        <div className="hui-alert__body">
          {includesIcon && <div className="hui-alert__icon"><Icon type={iconType(variant)} /></div>}
          <div className="hui-alert__content">
            {title && <div className="hui-alert__content-title">{title}</div>}
            {children}
          </div>
        </div>
      </div>) : null}
    </>
  );
}

export default Alert;
