import React from "react";
import {
  InvoiceQuoteContext,
  useInvoiceQuoteContext,
} from "./InvoiceQuoteContext";
import ContextLoader from "../invoices/ContextLoader";
import InvoiceTemplate from "./sections/InvoiceTemplate";

const TemplateWithContext = () => {
  const { invoiceObject, otherProps } = useInvoiceQuoteContext();
  if (invoiceObject) {
    const { model } = otherProps;
    const isQuote = model === "quote";

    return (
      <InvoiceTemplate
        showPayNowButton={!isQuote}
        showPaymentDetails={!isQuote}
        asPage
      />
    );
  }
  return null;
};

const InvoiceQuoteShow = (props) => (
  <InvoiceQuoteContext {...props}>
    <ContextLoader {...props} />
    <TemplateWithContext />
  </InvoiceQuoteContext>
);

export default InvoiceQuoteShow;
