import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import RecurrenceFields from "../invoice_quote/sections/RecurrenceFields";
import Comments from "../invoice_quote/sections/Comments";
import FooterButtons from "../invoice_quote/sections/FooterButtons";
import ScheduleInputs from "../invoice_quote/sections/ScheduleInputs";
import Accordion from "../accordion";
import HnryUIAccordion from "../_molecules/accordion/Accordion";
import DateInputs from "../invoice_quote/sections/DateInputs";
import PurchaseOrderField from "./purchase_order_field";
import DepositFields from "./deposit_fields";
import HidePhoneNumberField from "./hide_phone_number_field";
import { useInvoiceQuoteContext } from "../invoice_quote/InvoiceQuoteContext";
import InvoicePreviewModal from "../invoice_quote/modals/InvoicePreviewModal";
import NotSendingWarning from "./NotSendingWarning";

const SettingsAndFooter = ({
  advancedSettingsOpen,
  client,
  currencySymbol,
  defaultDueDateDays,
  depositsNotificationDismissed,
  invoiceClientSelected,
  invoiceStatus,
  isImpersonating = false,
  showUpdatedAdvancedOptionsSection = false,
}) => {
  const [disabledSave, setDisabledSave] = useState(false);
  const [dateInvalidText, setDateInvalidText] = useState("");
  const [timeInvalidText, setTimeInvalidText] = useState("");
  const [open, setOpen] = useState(false);

  const {
    comments,
    dueDate,
    deposit,
    endDate,
    invoiceDate,
    invoiceTotalIncludingSalesTax,
    poNumber,
    recurrenceConfig,
    scheduleDate,
    scheduleTime,
    setComments,
    setDueDate,
    setDeposit,
    setEndDate,
    setInvoiceDate,
    setPoNumber,
    setRecurrenceConfig,
    setScheduleDate,
    setScheduleTime,
    setStartDate,
    startDate,
    otherProps,
    setOtherProps,
  } = useInvoiceQuoteContext();

  const handlePhoneNumber = (value) => {
    setOtherProps({
      ...otherProps,
      hide_phone_number: value,
    });
  };

  useEffect(() => {
    if (
      dateInvalidText.length === 0 &&
      timeInvalidText.length === 0 &&
      invoiceTotalIncludingSalesTax >= 0
    ) {
      setDisabledSave(false);
    } else if (
      dateInvalidText.length > 0 ||
      timeInvalidText.length > 0 ||
      invoiceTotalIncludingSalesTax < 0
    ) {
      setDisabledSave(true);
    }
  }, [
    dateInvalidText,
    timeInvalidText,
    setDisabledSave,
    invoiceTotalIncludingSalesTax,
  ]);

  const openConfirmationModal = () => {
    $("#modal-invoice-email").modal("show");
  };

  const openPreviewModal = () => {
    $("#modal-preview-invoice").modal("show");
  };

  const handleSendNowClick = (event) => {
    event.preventDefault();
    setScheduleDate("");
    setScheduleTime("");
    openConfirmationModal();
    setDisabledSave(true);
  };

  const handleDateInvalidText = (value) => {
    setDateInvalidText(value);
  };

  const handleTimeInvalidText = (value) => {
    setTimeInvalidText(value);
  };

  const handleAccordionChanging = () => {
    setOpen(!open)
    if (!open) {
      window.analytics?.track("invoice_create_edit_extra_settings_opened", { menu_selected: "additional_settings" });
    }
  }

  const AccordionContent = showUpdatedAdvancedOptionsSection ? (
    <HnryUIAccordion
      title="Additional Settings"
      open={open}
      onOpenChange={handleAccordionChanging}
    >
      <div className="tw-grid tw-gap-8 sm:tw-grid-cols-2">
        <Comments comments={comments} setComments={setComments} />
        <PurchaseOrderField poNumber={poNumber} setPoNumber={setPoNumber} />
        <DepositFields
          isAlertDismissed={depositsNotificationDismissed}
          currencySymbol={currencySymbol}
          deposit={deposit}
          setDeposit={setDeposit}
        />
        <HidePhoneNumberField
          hidePhoneNumber={otherProps?.hide_phone_number || false}
          setHidePhoneNumber={handlePhoneNumber}
        />
      </div>
    </HnryUIAccordion>
  ) : (
    <Accordion
      title="Advanced Options"
      open={advancedSettingsOpen}
      unmountChildren={false}
      trackClick={{
        eventName: "invoice_create_edit_extra_settings_opened",
        data: { menu_selected: "additional_settings" },
      }}
    >
      <div className="tw-grid tw-gap-8 sm:tw-grid-cols-2">
        <DateInputs
          invoiceDate={invoiceDate}
          setInvoiceDate={setInvoiceDate}
          dueDate={dueDate}
          setDueDate={setDueDate}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <RecurrenceFields
          recurrenceConfig={recurrenceConfig}
          setRecurrenceConfig={setRecurrenceConfig}
        />
        <PurchaseOrderField poNumber={poNumber} setPoNumber={setPoNumber} />
        <DepositFields
          isAlertDismissed={depositsNotificationDismissed}
          currencySymbol={currencySymbol}
          deposit={deposit}
          setDeposit={setDeposit}
        />
        <Comments comments={comments} setComments={setComments} />
        <HidePhoneNumberField
          hidePhoneNumber={otherProps?.hide_phone_number || false}
          setHidePhoneNumber={handlePhoneNumber}
        />
      </div>
    </Accordion>
  );

  return (
    <>
      <NotSendingWarning
        client={client}
        invoiceClientSelected={invoiceClientSelected}
      />
      <ScheduleInputs
        dateInvalidText={dateInvalidText}
        defaultDueDateDays={defaultDueDateDays}
        dueDate={dueDate}
        endDate={endDate}
        invoiceDate={invoiceDate}
        scheduleDate={scheduleDate}
        scheduleTime={scheduleTime}
        startDate={startDate}
        setDateInvalidText={handleDateInvalidText}
        setDueDate={setDueDate}
        setEndDate={setEndDate}
        setInvoiceDate={setInvoiceDate}
        setScheduleDate={setScheduleDate}
        setScheduleTime={setScheduleTime}
        setStartDate={setStartDate}
        setTimeInvalidText={handleTimeInvalidText}
        showUpdatedAdvancedOptionsSection={showUpdatedAdvancedOptionsSection}
        timeInvalidText={timeInvalidText}
      />
      {AccordionContent}
      <hr />
      {disabledSave && (timeInvalidText || dateInvalidText) && (
        <div className="alert alert-info" role="alert">
          Schedule date is in the past. If you no longer wish to schedule the
          invoice you can&nbsp;
          <button className="hui-link tw-pt-0" onClick={handleSendNowClick}>
            <strong>send now</strong>
          </button>
        </div>
      )}
      <FooterButtons
        invoiceStatus={invoiceStatus}
        isImpersonating={isImpersonating}
        disabledSave={disabledSave}
        handleNextClick={openConfirmationModal}
        handlePreviewClick={openPreviewModal}
      />
      <InvoicePreviewModal disabledSave={disabledSave} />
    </>
  );
};

SettingsAndFooter.propTypes = {
  advancedSettingsOpen: PropTypes.bool.isRequired,
  client: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  defaultDueDateDays: PropTypes.number.isRequired,
  depositsNotificationDismissed: PropTypes.bool.isRequired,
  invoiceClientSelected: PropTypes.bool.isRequired,
  invoiceStatus: PropTypes.string.isRequired,
  isImpersonating: PropTypes.bool,
  showUpdatedAdvancedOptionsSection: PropTypes.bool,
};

export default SettingsAndFooter;
