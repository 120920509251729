const data = {
  items: [
    {
      name: "Share by email",
      value: 1,
    },
    {
      name: "Copy to clipboard",
      value: 2,
    },
    {
      name: "Download a deposit slip",
      value: 3,
    },
  ],
};

export default data;
