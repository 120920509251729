import { Address } from "@hui/_organisms/address_autocomplete/types";
import { iJobCategory } from "@hui/utils/JobCategorySelector/JobCategorySelectorHui";
import React, { useState } from "react";
import ensureJsonFieldsAreNotEmpty from "@hui/utils/jsonInspectionHelpers";
import { iWaitlistModal } from "../interface/WaitlistInterfaces";
import WaitlistInformation from "../WaitlistInformationRequired/WaitlistInformationRequired";
import WaitlistModal from "../Waitlist/WaitlistModal";


const WaitlistParent = ({
  formValues, jobCategories, jobCategoriesPresent, fallbackOptions ,
  nativeApp,
  appPlatformName,
  headerImagePath,
  currentUser
}: waitList) => {

  const [firstName, setFirstName] = useState(currentUser.name.first)
  const [address, setAddress] = useState(formValues)

  const updateWaitlistStatus = (updatedAddress: Address, updatedFirstName:string) => {
    setFirstName(updatedFirstName)
    setAddress(updatedAddress)
  }

  const requiredFieldsForAddress: (keyof typeof address)[]  = ["postTown", "postcode", "country"]


  return (<>

    { (!ensureJsonFieldsAreNotEmpty(address, requiredFieldsForAddress) || !firstName) ? 
      <WaitlistInformation 
        formValues={formValues} 
        jobCategories={jobCategories}  
        jobCategoriesPresent={jobCategoriesPresent} 
        fallbackOptions={fallbackOptions} 
        nativeApp={nativeApp}  
        currentUser={currentUser}
        updatedFields={updateWaitlistStatus}/>
      :
      <WaitlistModal
        nativeApp={nativeApp}
        appPlatformName={appPlatformName}
        headerImagePath={headerImagePath}
        currentUser={currentUser}/>
      
    }
  </>)

}

interface waitList extends iWaitlistModal {
    formValues: Address;
    jobCategories: iJobCategory[];
    jobCategoriesPresent: boolean;
    fallbackOptions: { id: number, title: string };

}

export default WaitlistParent