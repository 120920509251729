const data = [
  {
    key: "GB",
    value: "+44",
  },
  {
    key: "AU",
    value: "+61",
  },
  {
    key: "NZ",
    value: "+64",
  },
  {
    key: "BS",
    value: "+1",
  },
  {
    key: "EG",
    value: "+20",
  },
  {
    key: "SS",
    value: "+211",
  },
  {
    key: "EH",
    value: "+212",
  },
  {
    key: "DZ",
    value: "+213",
  },
  {
    key: "TN",
    value: "+216",
  },
  {
    key: "LY",
    value: "+218",
  },
  {
    key: "GM",
    value: "+220",
  },
  {
    key: "SN",
    value: "+221",
  },
  {
    key: "MR",
    value: "+222",
  },
  {
    key: "ML",
    value: "+223",
  },
  {
    key: "GN",
    value: "+224",
  },
  {
    key: "CI",
    value: "+225",
  },
  {
    key: "BF",
    value: "+226",
  },
  {
    key: "NE",
    value: "+227",
  },
  {
    key: "TG",
    value: "+228",
  },
  {
    key: "BJ",
    value: "+229",
  },
  {
    key: "MU",
    value: "+230",
  },
  {
    key: "LR",
    value: "+231",
  },
  {
    key: "SL",
    value: "+232",
  },
  {
    key: "GH",
    value: "+233",
  },
  {
    key: "NG",
    value: "+234",
  },
  {
    key: "TD",
    value: "+235",
  },
  {
    key: "CF",
    value: "+236",
  },
  {
    key: "CM",
    value: "+237",
  },
  {
    key: "CV",
    value: "+238",
  },
  {
    key: "ST",
    value: "+239",
  },
  {
    key: "GQ",
    value: "+240",
  },
  {
    key: "GA",
    value: "+241",
  },
  {
    key: "CG",
    value: "+242",
  },
  {
    key: "CD",
    value: "+243",
  },
  {
    key: "AO",
    value: "+244",
  },
  {
    key: "GW",
    value: "+245",
  },
  {
    key: "IO",
    value: "+246",
  },
  {
    key: "SC",
    value: "+248",
  },
  {
    key: "SD",
    value: "+249",
  },
  {
    key: "RW",
    value: "+250",
  },
  {
    key: "ET",
    value: "+251",
  },
  {
    key: "SO",
    value: "+252",
  },
  {
    key: "DJ",
    value: "+253",
  },
  {
    key: "KE",
    value: "+254",
  },
  {
    key: "TZ",
    value: "+255",
  },
  {
    key: "UG",
    value: "+256",
  },
  {
    key: "BI",
    value: "+257",
  },
  {
    key: "MZ",
    value: "+258",
  },
  {
    key: "ZM",
    value: "+260",
  },
  {
    key: "MG",
    value: "+261",
  },
  {
    key: "RE",
    value: "+262",
  },
  {
    key: "ZW",
    value: "+263",
  },
  {
    key: "NA",
    value: "+264",
  },
  {
    key: "MW",
    value: "+265",
  },
  {
    key: "LS",
    value: "+266",
  },
  {
    key: "BW",
    value: "+267",
  },
  {
    key: "SZ",
    value: "+268",
  },
  {
    key: "KM",
    value: "+269",
  },
  {
    key: "ZA",
    value: "+27",
  },
  {
    key: "SH",
    value: "+290",
  },
  {
    key: "ER",
    value: "+291",
  },
  {
    key: "AW",
    value: "+297",
  },
  {
    key: "FO",
    value: "+298",
  },
  {
    key: "GL",
    value: "+299",
  },
  {
    key: "GR",
    value: "+30",
  },
  {
    key: "NL",
    value: "+31",
  },
  {
    key: "BE",
    value: "+32",
  },
  {
    key: "FR",
    value: "+33",
  },
  {
    key: "ES",
    value: "+34",
  },
  {
    key: "GI",
    value: "+350",
  },
  {
    key: "PT",
    value: "+351",
  },
  {
    key: "LU",
    value: "+352",
  },
  {
    key: "IE",
    value: "+353",
  },
  {
    key: "IS",
    value: "+354",
  },
  {
    key: "AL",
    value: "+355",
  },
  {
    key: "MT",
    value: "+356",
  },
  {
    key: "CY",
    value: "+357",
  },
  {
    key: "FI",
    value: "+358",
  },
  {
    key: "BG",
    value: "+359",
  },
  {
    key: "HU",
    value: "+36",
  },
  {
    key: "LT",
    value: "+370",
  },
  {
    key: "LV",
    value: "+371",
  },
  {
    key: "EE",
    value: "+372",
  },
  {
    key: "MD",
    value: "+373",
  },
  {
    key: "AM",
    value: "+374",
  },
  {
    key: "BY",
    value: "+375",
  },
  {
    key: "AD",
    value: "+376",
  },
  {
    key: "MC",
    value: "+377",
  },
  {
    key: "SM",
    value: "+378",
  },
  {
    key: "UA",
    value: "+380",
  },
  {
    key: "RS",
    value: "+381",
  },
  {
    key: "ME",
    value: "+382",
  },
  {
    key: "HR",
    value: "+385",
  },
  {
    key: "SI",
    value: "+386",
  },
  {
    key: "BA",
    value: "+387",
  },
  {
    key: "MK",
    value: "+389",
  },
  {
    key: "IT",
    value: "+39",
  },
  {
    key: "RO",
    value: "+40",
  },
  {
    key: "CH",
    value: "+41",
  },
  {
    key: "CZ",
    value: "+420",
  },
  {
    key: "SK",
    value: "+421",
  },
  {
    key: "LI",
    value: "+423",
  },
  {
    key: "AT",
    value: "+43",
  },
  {
    key: "DK",
    value: "+45",
  },
  {
    key: "SE",
    value: "+46",
  },
  {
    key: "SJ",
    value: "+47",
  },
  {
    key: "PL",
    value: "+48",
  },
  {
    key: "DE",
    value: "+49",
  },
  {
    key: "GS",
    value: "+500",
  },
  {
    key: "BZ",
    value: "+501",
  },
  {
    key: "GT",
    value: "+502",
  },
  {
    key: "SV",
    value: "+503",
  },
  {
    key: "HN",
    value: "+504",
  },
  {
    key: "NI",
    value: "+505",
  },
  {
    key: "CR",
    value: "+506",
  },
  {
    key: "PA",
    value: "+507",
  },
  {
    key: "PM",
    value: "+508",
  },
  {
    key: "HT",
    value: "+509",
  },
  {
    key: "PE",
    value: "+51",
  },
  {
    key: "MX",
    value: "+52",
  },
  {
    key: "CU",
    value: "+53",
  },
  {
    key: "AR",
    value: "+54",
  },
  {
    key: "BR",
    value: "+55",
  },
  {
    key: "CL",
    value: "+56",
  },
  {
    key: "CO",
    value: "+57",
  },
  {
    key: "VE",
    value: "+58",
  },
  {
    key: "GP",
    value: "+590",
  },
  {
    key: "BO",
    value: "+591",
  },
  {
    key: "GY",
    value: "+592",
  },
  {
    key: "EC",
    value: "+593",
  },
  {
    key: "GF",
    value: "+594",
  },
  {
    key: "PY",
    value: "+595",
  },
  {
    key: "MQ",
    value: "+596",
  },
  {
    key: "SR",
    value: "+597",
  },
  {
    key: "UY",
    value: "+598",
  },
  {
    key: "CW",
    value: "+599",
  },
  {
    key: "MY",
    value: "+60",
  },
  {
    key: "ID",
    value: "+62",
  },
  {
    key: "PH",
    value: "+63",
  },
  {
    key: "SG",
    value: "+65",
  },
  {
    key: "TH",
    value: "+66",
  },
  {
    key: "TL",
    value: "+670",
  },
  {
    key: "AQ",
    value: "+672",
  },
  {
    key: "BN",
    value: "+673",
  },
  {
    key: "NR",
    value: "+674",
  },
  {
    key: "PG",
    value: "+675",
  },
  {
    key: "TO",
    value: "+676",
  },
  {
    key: "SB",
    value: "+677",
  },
  {
    key: "VU",
    value: "+678",
  },
  {
    key: "FJ",
    value: "+679",
  },
  {
    key: "PW",
    value: "+680",
  },
  {
    key: "WF",
    value: "+681",
  },
  {
    key: "CK",
    value: "+682",
  },
  {
    key: "NU",
    value: "+683",
  },
  {
    key: "WS",
    value: "+685",
  },
  {
    key: "KI",
    value: "+686",
  },
  {
    key: "NC",
    value: "+687",
  },
  {
    key: "TV",
    value: "+688",
  },
  {
    key: "PF",
    value: "+689",
  },
  {
    key: "TK",
    value: "+690",
  },
  {
    key: "FM",
    value: "+691",
  },
  {
    key: "MH",
    value: "+692",
  },
  {
    key: "RU",
    value: "+7",
  },
  {
    key: "JP",
    value: "+81",
  },
  {
    key: "KR",
    value: "+82",
  },
  {
    key: "VN",
    value: "+84",
  },
  {
    key: "KP",
    value: "+850",
  },
  {
    key: "HK",
    value: "+852",
  },
  {
    key: "MO",
    value: "+853",
  },
  {
    key: "KH",
    value: "+855",
  },
  {
    key: "LA",
    value: "+856",
  },
  {
    key: "CN",
    value: "+86",
  },
  {
    key: "BD",
    value: "+880",
  },
  {
    key: "TW",
    value: "+886",
  },
  {
    key: "TR",
    value: "+90",
  },
  {
    key: "IN",
    value: "+91",
  },
  {
    key: "PK",
    value: "+92",
  },
  {
    key: "AF",
    value: "+93",
  },
  {
    key: "LK",
    value: "+94",
  },
  {
    key: "MM",
    value: "+95",
  },
  {
    key: "MV",
    value: "+960",
  },
  {
    key: "LB",
    value: "+961",
  },
  {
    key: "JO",
    value: "+962",
  },
  {
    key: "SY",
    value: "+963",
  },
  {
    key: "IQ",
    value: "+964",
  },
  {
    key: "KW",
    value: "+965",
  },
  {
    key: "SA",
    value: "+966",
  },
  {
    key: "YE",
    value: "+967",
  },
  {
    key: "OM",
    value: "+968",
  },
  {
    key: "PS",
    value: "+970",
  },
  {
    key: "AE",
    value: "+971",
  },
  {
    key: "IL",
    value: "+972",
  },
  {
    key: "BH",
    value: "+973",
  },
  {
    key: "QA",
    value: "+974",
  },
  {
    key: "BT",
    value: "+975",
  },
  {
    key: "MN",
    value: "+976",
  },
  {
    key: "NP",
    value: "+977",
  },
  {
    key: "IR",
    value: "+98",
  },
  {
    key: "TJ",
    value: "+992",
  },
  {
    key: "TM",
    value: "+993",
  },
  {
    key: "AZ",
    value: "+994",
  },
  {
    key: "GE",
    value: "+995",
  },
  {
    key: "KG",
    value: "+996",
  },
  {
    key: "UZ",
    value: "+998",
  },
];

export default data;
