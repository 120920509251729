import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import Logo from "../../../../assets/images/hnry-logo-name-only-inverted.svg";
import { PlatformType, iNavItem, iSidebarPanelProps } from "./types";
import Icon from "../../_atoms/icons/icon/Icon";
import { User } from "../../../global";

const itemClasses = [
  "-!tw-mx-2",
  "tw-flex",
  "tw-font-light",
  "tw-gap-x-1",
  "tw-group",
  "tw-leading-6",
  "tw-p-2",
  "tw-rounded-md",
  "tw-text-brand-200",
  "tw-text-sm",
  "tw-text-white",
  "hover:tw-bg-brand-600",
  "hover:tw-text-white",
].join(" ");

const itemLinkClasses = [
  "tw-flex",
  "tw-grow",
  "tw-gap-x-3",
  "tw-text-white",
  "hover:tw-bg-brand-600",
  "hover:tw-text-white",
].join(" ");

const iconForItem = (iconType: string, current = false) => (
  <Icon
    classes={classNames(
      { "group-hover:tw-text-white": current },
      "tw-text-white tw-h-6 tw-w-6 tw-shrink-0",
    )}
    type={iconType}
    aria-hidden="true"
  />
);

const activeUrl = (url: string) => {
  const urlObject = new URL(url, window.location.origin);
  return window.location.pathname === urlObject.pathname;
};

const groupHasActiveItems = (items: iNavItem[]) => items.some((item) => activeUrl(item.href));

const annotateActiveState = (items: iNavItem[]) => items.map((item) => ({
  ...item,
  current: activeUrl(item.href),
}));

const IntercomNavigation = (
  platform: PlatformType,
  currentUser: User,
  closeSidepanel: () => void,
) => {
  const navigationLabel = "Help";
  const isNative = ["native_ios", "native_android"].includes(platform);

  if (isNative || typeof Intercom === "undefined") {
    const href = isNative
      ? "/app/open_intercom"
      : `mailto:${currentUser.jurisdiction.supportEmail}`;

    return (
      <a
        href={href}
        className={`tw-w-full ${itemClasses}`}
        data-turbolinks={platform === "native_ios"}
        data-track-click='{ "eventName": "sidebar_help_button_clicked" }'
      >
        {navigationLabel}
      </a>
    );
  }

  return (
    <button
      onClick={() => {
        closeSidepanel();
        Intercom("show");
      }}
      className={`tw-w-full ${itemClasses}`}
      data-track-click='{ "eventName": "sidebar_help_button_clicked" }'
    >
      {navigationLabel}
    </button>
  );
};

const NavigationSidebarPanel = ({
  closeSidepanel,
  navItems,
  platform,
  settingItems,
  settingsPath,
  signOutPath,
  currentUser,
}: iSidebarPanelProps) => {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  const updateCurrentPath = () => {
    setCurrentPath(window.location.pathname);
  };

  useEffect(() => {
    document.addEventListener("turbolinks:load", updateCurrentPath);
    return () => {
      document.removeEventListener(
        "turbolinks:load",
        updateCurrentPath,
      );
    };
  }, [currentPath]);

  return (
    <nav className="tw-flex tw-grow tw-flex-col tw-gap-y-5 tw-overflow-y-auto tw-px-6 tw-pb-4 tw-bg-gradient-to-tl tw-from-[#000333] tw-from-20% tw-to-[#0C105A]">
      <div>
        <a className="tw-block tw-text-center" href="/">
          <img
            className="tw-inline-block tw-mt-8 tw-mb-3 tw-max-h-24"
            src={Logo}
            alt="Hnry Logo"
            width="268"
            height="117"
          />
        </a>
      </div>
      <div className="tw-flex tw-flex-1">
        <ul className="tw-flex tw-flex-1 tw-flex-col tw-gap-y-7">
          {/* Primary nav items */}
          <li>
            <ul className="tw-space-y-1">
              {annotateActiveState(navItems).map((item) => (
                <li
                  key={item.href}
                  className={classNames(
                    { "tw-bg-brand-600": item.current },
                    itemClasses,
                  )}
                >
                  <a
                    href={item.href}
                    className={itemLinkClasses}
                    data-track-click={
                      item.eventName
                        ? JSON.stringify({ eventName: item.eventName })
                        : null
                    }
                  >
                    {iconForItem(item.icon, item.current)}
                    {item.name}
                  </a>
                  {item.newPath && (
                    <a
                      className="hnry-badge tw-ml-auto tw-bg-white/20 !tw-font-semibold hover:tw-outline hover:tw-outline-1 hover:tw-outline-white/50 tw-text-white hover:tw-text-white"
                      data-remote="true"
                      data-track-click={JSON.stringify({
                        eventName: `sidebar_${item.name}_new_click`,
                      })}
                      href={item.newPath}
                      title={item.newLabel || "New"}
                    >
                      + <span className="tw-sr-only">New</span>
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </li>

          <li>
            <Disclosure defaultOpen={groupHasActiveItems(settingItems)}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className={classNames(
                      itemClasses,
                      "tw-font-semibold",
                      "tw-w-full",
                      "tw-mb-1",
                    )}
                  >
                    Your account
                    <ChevronDownIcon
                      className={`tw-w-4 tw-inline-block tw-self-center ${
                        open ? "tw-rotate-180 tw-transform" : ""
                      }`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="text-gray-500">
                    <ul className="tw-space-y-1">
                      {annotateActiveState(settingItems).map((item) => (
                        <li
                          key={item.href}
                          className={classNames(
                            { "tw-bg-brand-600": item.current },
                            itemClasses,
                          )}
                        >
                          <a
                            href={item.href}
                            data-turbolinks="true"
                            className={itemLinkClasses}
                            data-track-click={
                              item.eventName
                                ? JSON.stringify({ eventName: item.eventName })
                                : null
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
          <li className="tw-border-t tw-border-white"></li>
          <li>
            <ul>
              <li>{IntercomNavigation(platform, currentUser, closeSidepanel)}</li>
              <li>
                <button
                  className={`tw-w-full ${itemClasses}`}
                  onClick={(e) => {
                    e.preventDefault();
                    closeSidepanel();
                    document.dispatchEvent(new Event("hnry:showShareDialog"));
                  }}
                  data-track-click={JSON.stringify({
                    eventName: "sidebar_refer_a_friend_click",
                  })}
                >
                  Refer a friend
                </button>
              </li>
              <li>
                <a
                  href={signOutPath}
                  className={`tw-w-full ${itemClasses}`}
                  data-turbolinks={platform === "native_ios"}
                  data-track-click={JSON.stringify({
                    eventName: "sidebar_logout_link_clicked",
                  })}
                >
                  {iconForItem("ArrowRightOnRectangleIcon")} Sign out
                </a>
              </li>
            </ul>
          </li>

          <li className="mt-auto">
            <a
              className="tw-text-white hover:tw-text-white tw-flex tw-items-center tw-gap-4 tw-group"
              data-track-click={JSON.stringify({
                eventName: "sidebar_profile_bubble_click",
              })}
              href={settingsPath}
            >
              <span className="tw-block tw-rounded-full tw-bg-brand-orange-800">
                <span
                  className="tw-flex tw-w-12 tw-h-12 tw-items-center tw-justify-center"
                  aria-hidden="true"
                >
                  <span className="[text-shadow:_0_0_2px_#222]">
                    {currentUser.name.initials || (
                      <Icon
                        type="UserCircleIcon"
                        classes="tw-text-white"
                        size="lg"
                      />
                    )}
                  </span>
                </span>
              </span>
              <span className="tw-grow-1 group-hover:tw-underline tw-text-sm">
                {currentUser.name.full || "Your Profile"}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}

NavigationSidebarPanel.displayName = "NavigationSidebarPanel";

export default NavigationSidebarPanel;
