import React, { useEffect, useState } from "react";
import OtpCodeInput from "../../_atoms/otp_input/OtpInput";
import { get, postJson } from "../../../API/config/fetch.api";

export interface iAuthModal {
  setCardDetails: (cardDetails: object) => void
  sendSMS: () => void
  authenticatorApp?: boolean
  userEmail?: string
}

const AuthModal = ({
  setCardDetails,
  authenticatorApp,
  userEmail,
  sendSMS,
}: iAuthModal) => {
  const [otpCode, setOtpCode] = useState("");

  const sendOtp = () => {
    get(Routes.send_cards_mfa_cards_path()).then((response) => {
      if (response.message) {
        toastr.success(response.message);
      }
    }).catch(() => {
      toastr.error("Failed to send MFA code");
    });
  };

  useEffect(() => {
    sendOtp();
  }, []);

  const submitOtp = (otp: string) => {
    postJson(Routes.card_details_cards_path(), JSON.stringify({ token: otp })).then((response) => {
      setCardDetails(JSON.parse(response.card_details));
      sendSMS();
    }).catch(() => {
      toastr.error("Incorrect code entered, please try again");
    });
  };

  const handleOtpChange = (otp: string) => {
    setOtpCode(otp);
    if (otp.length === 6) {
      submitOtp(otp);
    }
  };

  return (
    <div>
      <div className="tw-mb-5">
        {
          authenticatorApp
            ? <p className={"tw-mb-5"}>Enter the code from your authenticator app</p>
            : <p className={"tw-mb-0"}>We sent an MFA code to: <strong>{userEmail}</strong></p>
        }
      </div>
      <div className="tw-my-6 tw-mx-0 tw-py-2 tw-px-0 sm:tw-px-6">
        <OtpCodeInput value={otpCode} inputName="otp_code" onChange={handleOtpChange} />
      </div>
    </div>
  );
};

export default AuthModal;
