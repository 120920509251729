import React from "react";
import SVG from "react-inlinesvg";
import { AnimatePresence, motion } from "framer-motion";
import Cross from "../../../../assets/images/icons/cross-circle.svg";

const ClearButton = ({ isVisible, onClick }) => (
  <AnimatePresence>
    { isVisible && <motion.button
      initial="inactive"
      animate="active"
      exit="inactive"
      onClick={() => onClick()}
      variants={{
        active: { y: "-50%", scale: 1 },
        inactive: { y: "-50%", scale: 0 },
      }}
      transition={{ type: "tween", duration: 0.05 }}
      className="clear-input"
      aria-label="Clear this input"
      type="button"
    >
      <SVG src={Cross} aria-hidden="true" />
    </motion.button> }
  </AnimatePresence>
);

export default ClearButton;
