import React from "react";
import Tooltip from "../../_atoms/tooltip/Tooltip";
import Input, { iInput } from "../../_atoms/input/Input";

interface iInputWithTooltip extends iInput {
  id: string;
  label: string;
  popoverMessage: string;
}

const InputWithTooltip = ({
  id,
  label,
  type = "text",
  popoverMessage,
  name,
  step,
  rightIcon,
  setValue,
  value,
  defaultValue,
  max,
  required,
  disabled,
  placeholder = ""
}: iInputWithTooltip) => {
  const idWithFallback = id || name || crypto.randomUUID();
  return (
    <>
      <div className="tw-flex">
        <label className={required ? "hnry-label after:tw-content-['*'] after:tw-inline after:tw-text-red after:tw-ml-1" : "hnry-label"} htmlFor={idWithFallback}>
          {label}
        </label>
        <Tooltip
          hideOnClick
          popoverMessage={popoverMessage}
          size="base"
          trigger="click"
          placement="top"
        />
      </div>
      <Input
        id={idWithFallback}
        type={type}
        labelRendered={false}
        name={name}
        step={step}
        rightIcon={rightIcon}
        setValue={setValue}
        value={value}
        defaultValue={defaultValue}
        max={max}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
      />
    </>
  )
};

export default InputWithTooltip;
