const data = {
  items: [
    {
      name: "YTD",
      value: "YTD",
    },
    {
      name: "2023/24",
      value: "2023/24",
    },
    {
      name: "2022/23",
      value: "2022/23",
    },
    {
      name: "2021/22",
      value: "2021/22",
    },
  ],
};

export default data;
