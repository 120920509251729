import React from "react";
import Datepicker, {
  DatepickerInputProps,
} from "../../inputs/datepicker/datepicker";
import I18n from "../../../utilities/translations";
import { SetDate } from "../../../types/invoices.type";

interface iDateBlock extends DatepickerInputProps {
  label: string;
  requiredLabel?: boolean;
  tooltipText?: string;
  displayTooltip?: boolean;
}

interface iDateInputs {
  invoiceDate: Date;
  setInvoiceDate: SetDate;

  dueDate: Date;
  setDueDate: SetDate;

  startDate: Date;
  setStartDate: SetDate;

  endDate: Date;
  setEndDate: SetDate;
  showUpdatedAdvancedOptionsSection?: boolean;
}

export const DateBlock = ({ label, requiredLabel, tooltipText, displayTooltip, ...inputProps }: iDateBlock) => (
  <Datepicker legacyStyles={false} {...{ label, requiredLabel, tooltipText, displayTooltip, inputProps }} />
);

const ctx = { scope: "invoices.form" };

const DateInputs = ({
  invoiceDate,
  setInvoiceDate,
  dueDate,
  setDueDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  showUpdatedAdvancedOptionsSection = false
}: iDateInputs) => {
  const startDateTooltipText = showUpdatedAdvancedOptionsSection ? I18n.t("start_date", ctx) : "";
  const endDateTooltipText = showUpdatedAdvancedOptionsSection ? I18n.t("end_date", ctx) : "";

  return (
    <>
      <DateBlock
        label={I18n.t("invoice_date", ctx)}
        value={invoiceDate}
        name="invoice[invoice_date]"
        onChange={(date) => setInvoiceDate(date)}
        requiredLabel={true}
        displayTooltip={showUpdatedAdvancedOptionsSection}
      />
      <DateBlock
        label={I18n.t("due_date", ctx)}
        value={dueDate}
        name="invoice[due_date]"
        onChange={(date) => setDueDate(date)}
        requiredLabel={true}
        displayTooltip={showUpdatedAdvancedOptionsSection}
      />

      <DateBlock
        label={I18n.t("period_start_date", ctx)}
        value={startDate}
        name="invoice[period_start_date]"
        onChange={(date) => setStartDate(date)}
        tooltipText={startDateTooltipText}
        displayTooltip={showUpdatedAdvancedOptionsSection}
      />
      <DateBlock
        label={I18n.t("period_end_date", ctx)}
        value={endDate}
        name="invoice[period_end_date]"
        onChange={(date) => setEndDate(date)}
        tooltipText={endDateTooltipText}
        displayTooltip={showUpdatedAdvancedOptionsSection}
      />
    </>
  )
};

export default DateInputs;
