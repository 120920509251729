import React, { useMemo } from "react";
import RadioButtonDropdown from "../../_molecules/dropdown/RadioButtonDropdown";
import { KeyValuePair } from "./types";

interface iFilterDropdown {
  fieldName: string;
  filterName: string;
  items: KeyValuePair[];
  onChange: (item: KeyValuePair) => void;
  selected: string;
}

const FilterDropdown = ({
  fieldName,
  filterName,
  items,
  onChange,
  selected,
}: iFilterDropdown) => {
  const filterItems = useMemo(() => (
    items.map(({ value, key }) => ({ value: key, name: value }))
  ), [items]);

  const handleChange = (value) => {
    onChange({ key: fieldName, value });
  };

  const formattedSelected = useMemo(() => {
    const index = filterItems.findIndex(({ value }) => selected === value)

    return filterItems[index];
  }, [filterItems, selected]);

  return (
    <div className="tw-min-w-[6rem] tw-hidden sm:tw-block" id={`${filterName}-dropdown`}>
      <RadioButtonDropdown
        items={filterItems}
        onChange={handleChange}
        selected={formattedSelected}
        fieldName={fieldName}
        ariaLabel={`Filter by ${filterName}`}
        id={`${filterName}-dropdown`}
      />
    </div>
  );
};

export default FilterDropdown;
