import React, { forwardRef, HTMLAttributes } from "react";
import classNames from "classnames";
import Icon from "../_atoms/icons/icon/Icon";
import ImageItem from "./ImageItem";

export type ModuleItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  dashedBorder?: boolean;
  moduleData: object;
};

const ModuleItem = forwardRef<HTMLDivElement, ModuleItemProps>(
  (
    {
      dashedBorder = false,
      id,
      isDragging,
      moduleData,
      style,
      withOpacity,
      ...props
    },
    ref
  ) => {
  const shared = "tw-min-h-10 sm:tw-min-h-80 xl:tw-min-h-72 tw-rounded-lg tw-flex";
  const border = "tw-rounded-lg tw-border-4";
  const wrapper = "tw-items-center tw-justify-center tw-bg-gray-100 tw-shadow-md tw-origin-[50%_50%]";
  const classes = classNames(wrapper, {
    [`${border}`]: border,
    "tw-border-dashed tw-border-gray-200": dashedBorder,
    "tw-border-solid tw-border-gray-100": !dashedBorder,
    [`${shared}`]: shared,
    "tw-opacity-50": withOpacity,
    "tw-opacity-100": !withOpacity,
    "tw-cursor-grabbing": isDragging,
    "tw-cursor-grab": !isDragging,
    "tw-scale-105": isDragging,
    "tw-scale-100": !isDragging,
  });

  return (
    <div
      ref={ref}
      className={classes}
      style={style}
      {...props}
    >
      <div className={`${shared} tw-flex-row sm:tw-flex-col tw-items-center tw-w-full tw-m-[0.25rem] tw-border tw-bg-white tw-text-center tw-py-2 sm:tw-py-6 tw-px-2 sm:tw-px-8`}>
        <ImageItem
          moduleName={id}
          className="sm:tw-hidden tw-h-11 tw-rounded-md tw-bg-gray-100 tw-mr-2 xs:tw-mr-3"
          alt={moduleData[`${id}`].alt}
          mobile
        />
        <h2 className="hnry-heading hnry-heading--h1 !tw-text-sm sm:!tw-text-lg tw-mb-0">{moduleData[`${id}`].title}</h2>
        <span className="tw-ml-auto sm:tw-hidden"><Icon type="DoubleEllipsisIcon" size="lg" /></span>
        <p className="tw-hidden sm:tw-block tw-text-sm tw-text-gray-500 sm:tw-py-5 xl:tw-py-3">{moduleData[`${id}`].description}</p>
        <ImageItem moduleName={id} className="tw-hidden sm:tw-block tw-h-[8rem]" alt={moduleData[`${id}`].alt} />
      </div>
    </div>
  );
});

ModuleItem.displayName = "Item";

export default ModuleItem;
