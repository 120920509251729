import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const Alert = ({
  id, type, children, dismissible, dismissed, className, ...otherProps
}) => {
  const [classes] = useState(`alert alert-${type} ${className || ""}`);
  const [isDismissed, setIsDismissed] = useState(dismissed);

  const handleDismiss = () => {
    $.rails.ajax({
      type: "POST",
      url: `/dismissed_notifications?notification=${id}`,
      dataType: "script",
    });
    setIsDismissed(true);
  };

  const alertStyles = {
    visible: {
      height: "auto",
    },
    hidden: {
      height: 0,
      overflow: "hidden",
    },
  };

  return (
    <AnimatePresence>
      {!isDismissed && (
        <motion.div
          {...otherProps}
          className={classes}
          role="alert"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={alertStyles}
          transition={{
            ease: "easeInOut",
            duration: "0.4",
          }}
        >
          {children}
          {dismissible && (
            <button
              className="close-button"
              aria-label="Dismiss info box"
              type="button"
              onClick={() => handleDismiss()}
            >
              <i className="fa fa-close" aria-hidden="true" />
            </button>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
