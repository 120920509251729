import React, { useEffect } from "react";
import Tooltip from "../../tooltip";
import { ordinalize } from "../../utils/general_helpers";
import I18n from "../../../utilities/translations";

type SpecifiedRecurrencePeriods =
  | "weekly"
  | "fortnightly"
  | "monthly"
  | "annually";

export type RecurrencePeriod =
  | SpecifiedRecurrencePeriods
  | "never"
  | "indefinitely";

export type RecurrenceConfig = {
  indefinitePeriod: SpecifiedRecurrencePeriods | "";
  recurrenceDay: number;
  recurrenceMaxOccurrences: number;
  recurrencePeriod: RecurrencePeriod;
  recursIndefinitely?: boolean;
};

export interface iRecurrenceFields {
  recurrenceConfig: RecurrenceConfig;
  setRecurrenceConfig: (value: RecurrenceConfig) => void;
}

const ctx = { scope: "invoices.recurrence" };

const RecurrencePeriod = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const periodOptions = [
    { label: "Never", value: "never" },
    { label: "Weekly", value: "weekly" },
    { label: "Fortnightly", value: "fortnightly" },
    { label: "Monthly", value: "monthly" },
    { label: "Annually", value: "annually" },
    { label: "Indefinitely", value: "indefinitely" },
  ];

  const handleRecurrencePeriodChange = ({ target }) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recurrencePeriod: target.value,
      indefinitePeriod: target.value === "indefinitely" ? "weekly" : "",
      recurrenceDay: 1,
      recurrenceMaxOccurrences: 1,
    });
  };

  return (
    <div className="tw-inline-block">
      <label
        className="form-control-mini"
        htmlFor="invoice[invoice_config_attributes][recurrence_period]"
      >
        Invoice repeats
        <select
          className="form-control form-control-mini !tw-box-content !tw-mx-4 !tw-my-0 !tw-py-2"
          id="invoice[invoice_config_attributes][recurrence_period]"
          name="invoice[invoice_config_attributes][recurrence_period]"
          value={recurrenceConfig.recurrencePeriod}
          onChange={(event) => handleRecurrencePeriodChange(event)}
        >
          {periodOptions.map((option, key) => (
            <option value={option.value} key={key}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
      <span className="hidden-md-up ml-2">
        <Tooltip text={I18n.t("tooltip_legacy", ctx)} />
      </span>
    </div>
  );
};

const IndefinitelyOptions = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const periodOptions = [
    { label: "Fortnightly", value: "fortnightly" },
    { label: "Weekly", value: "weekly" },
    { label: "Monthly", value: "monthly" },
    { label: "Annually", value: "annually" },
  ];

  const handleIndefinitelyOptionsChange = ({ target }) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      indefinitePeriod: target.value,
    });
  };

  return (
    <div className="tw-inline-block">
      <label className="form-control-mini" htmlFor="ui[indefinite_period]">
        Repeats
        <select
          className="form-control form-control-mini !tw-box-content !tw-mx-4 !tw-my-0 !tw-py-2"
          id="ui[indefinite_period]"
          name="ui[indefinite_period]"
          value={recurrenceConfig.indefinitePeriod}
          onChange={(event) => handleIndefinitelyOptionsChange(event)}
        >
          {periodOptions.map((option, key) => (
            <option value={option.value} key={key}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
      <span className="hidden-md-up ml-2">
        <Tooltip text={I18n.t("tooltip_legacy", ctx)} />
      </span>
    </div>
  );
};

const RecurrenceDay = ({
  setRecurrenceConfig,
  recurrenceConfig,
}: iRecurrenceFields) => {
  const isRecurrence = (value) => {
    const recurring = recurrenceConfig.recurrencePeriod;
    const indefinite = recurrenceConfig.indefinitePeriod;
    return recurring === "indefinitely"
      ? indefinite === value
      : recurring === value;
  };

  const recurrenceDayOptions = () => {
    let options = [];

    if (recurrenceConfig.recurrencePeriod !== "never") {
      if (isRecurrence("monthly")) {
        const monthlyDayOptions = new Array(28).fill(0).map((_, index) => {
          const step = index + 1;
          return {
            label: ordinalize(step),
            value: step,
          };
        });
        options = [...monthlyDayOptions];
      } else if (isRecurrence("annually")) {
        options = [];
      } else {
        // weekly or fortnightly
        options = [
          { label: "Mondays", value: "1" },
          { label: "Tuesdays", value: "2" },
          { label: "Wednesdays", value: "3" },
          { label: "Thursdays", value: "4" },
          { label: "Fridays", value: "5" },
          { label: "Saturdays", value: "6" },
          { label: "Sundays", value: "0" },
        ];
      }
    }
    return options;
  };

  const handleRecurrenceDayChange = ({ target }) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recurrenceDay: target.value,
    });
  };

  return (
    <div className="tw-inline-block">
      <label
        className="form-control-mini"
        htmlFor="invoice[invoice_config_attributes][recurrence_day]"
      >
        on {recurrenceConfig.recurrencePeriod === "monthly" ? "the" : ""}
        <select
          className="form-control form-control-mini !tw-box-content !tw-mx-4 !tw-my-0 !tw-py-2"
          id="invoice[invoice_config_attributes][recurrence_day]"
          name="invoice[invoice_config_attributes][recurrence_day]"
          value={recurrenceConfig.recurrenceDay}
          onChange={(event) => handleRecurrenceDayChange(event)}
        >
          {recurrenceDayOptions().map((option, key) => (
            <option value={option.value} key={key}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

const RecurrenceOccurrence = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  const handleRecurrenceOccurrencesChange = ({ target }) => {
    setRecurrenceConfig({
      ...recurrenceConfig,
      recurrenceMaxOccurrences: target.value,
    });
  };

  return (
    <div className="tw-inline-block">
      <label
        className="form-control-mini"
        htmlFor="invoice[invoice_config_attributes][recurrence_max_occurrences]"
      >
        for
        <input
          className="form-control-mini !tw-h-auto !tw-mx-4 !tw-my-0 !tw-py-2 !tw-border-b-0"
          type="number"
          id="invoice[invoice_config_attributes][recurrence_max_occurrences]"
          name="invoice[invoice_config_attributes][recurrence_max_occurrences]"
          value={recurrenceConfig.recurrenceMaxOccurrences}
          min="1"
          max="104"
          onChange={(event) => handleRecurrenceOccurrencesChange(event)}
        />
        occurrence{recurrenceConfig.recurrenceMaxOccurrences != 1 ? "s" : ""}
      </label>
      <span className="hidden-small-down ml-2">
        <Tooltip text={I18n.t("tooltip_legacy", ctx)} />
      </span>
    </div>
  );
};

const RecurrenceFields = ({
  recurrenceConfig,
  setRecurrenceConfig,
}: iRecurrenceFields) => {
  // Note: Extra complexity due to use of 2 similar dropdowns
  // - recurrence_period: [never, weekly, fortnightly, monthly, annually, indefinite]
  // - indefinite_period: [weekly, fortnightly, monthly, annually]

  useEffect(() => {
    if (recurrenceConfig?.recursIndefinitely) {
      const indefinitePeriod: SpecifiedRecurrencePeriods = [
        "weekly",
        "fortnightly",
        "monthly",
        "annually",
      ].includes(recurrenceConfig?.recurrencePeriod)
        ? (recurrenceConfig.recurrencePeriod as SpecifiedRecurrencePeriods)
        : "weekly";
      setRecurrenceConfig({
        ...recurrenceConfig,
        indefinitePeriod,
        recurrencePeriod: "indefinitely",
      });
    }
  }, [recurrenceConfig?.recursIndefinitely]);

  const isRecurrence = (value) => recurrenceConfig?.recurrencePeriod === value;
  const isIndefiniteRecurrence = (value) =>
    recurrenceConfig?.indefinitePeriod === value;
  const isAnyRecurrence = (value) =>
    isRecurrence(value) || isIndefiniteRecurrence(value);

  const showRecurrenceOccurrence =
    isRecurrence("weekly") ||
    isRecurrence("fortnightly") ||
    isRecurrence("monthly") ||
    isRecurrence("annually");
  const showIndefinitelyOptions = isRecurrence("indefinitely");
  const showRecurrenceDay =
    isAnyRecurrence("weekly") ||
    isAnyRecurrence("fortnightly") ||
    isAnyRecurrence("monthly");

  return recurrenceConfig ? (
    <div className="sm:tw-col-span-2">
      <RecurrencePeriod {...{ recurrenceConfig, setRecurrenceConfig }} />
      {showIndefinitelyOptions && (
        <IndefinitelyOptions {...{ recurrenceConfig, setRecurrenceConfig }} />
      )}
      {showRecurrenceDay && (
        <RecurrenceDay {...{ recurrenceConfig, setRecurrenceConfig }} />
      )}
      {showRecurrenceOccurrence && (
        <RecurrenceOccurrence {...{ recurrenceConfig, setRecurrenceConfig }} />
      )}
    </div>
  ) : null;
};

export default RecurrenceFields;
