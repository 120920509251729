const SEQUENCE_LABEL_COMPONENTS = {
  alpha: {
    start: "A",
    end: "Z",
    connector: "–",
  },
  date: {
    start: "old",
    end: "new",
    connector: "to",
  },
  numeric: {
    start: "low",
    end: "high",
    connector: "to",
  },
};

export default SEQUENCE_LABEL_COMPONENTS;
