import React from "react";
import I18n from "../../../utilities/translations";


const NativeAppContent = () => (
    <div className="tw-text-gray-700">
        <p>{I18n.t("home.uk_waitlist.paragraph_1")}</p>
        <p className="tw-mb-0 tw-font-bold">
            {I18n.t("home.uk_waitlist.paragraph_2.title")}
        </p>
        <p>{I18n.t("home.uk_waitlist.paragraph_2.native_app")}</p>
    </div>
)


export default NativeAppContent