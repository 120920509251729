import React from "react";
import I18n from "../../../utilities/translations";
import Switch from "../../_atoms/switch/Switch";

interface iHidePhoneNumber {
  hidePhoneNumber: boolean;
  setHidePhoneNumber: (value: boolean) => void;
}

const HidePhoneNumberField = ({
  hidePhoneNumber,
  setHidePhoneNumber,
}: iHidePhoneNumber) => (
  <Switch
    id="hide-my-phone-number"
    label={I18n.t("invoices.form.hide_phone_number_label")}
    checked={hidePhoneNumber}
    onChange={() => setHidePhoneNumber(!hidePhoneNumber)}
  />
);

export default HidePhoneNumberField;
