import React from "react";
import Dropdown from "./Dropdown";
import RadioButtonList, { RadioButtonListItem } from "../radio_button_list/RadioButtonList";

export interface iRadioButtonDropdown {
  ariaLabel: string;
  fieldName?: string;
  items: RadioButtonListItem[];
  onChange: (arg0: RadioButtonListItem) => void;
  selected: RadioButtonListItem;
  id: string;
}

const RadioButtonDropdown = ({
  ariaLabel,
  fieldName,
  id,
  items,
  onChange,
  selected,
}: iRadioButtonDropdown) => {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <>
      <Dropdown id={id} buttonText={selected.name} ariaLabel={ariaLabel}>
        <RadioButtonList
          items={items}
          value={selected.value}
          onChange={handleChange}
          groupLabel={fieldName}
        />
      </Dropdown>
      {fieldName &&
        <>
          <label htmlFor={fieldName}>
            <input type="hidden" name={fieldName} value={selected.value} />
          </label>
        </>
      }
    </>
  );
};

export default RadioButtonDropdown;
