import React from "react";
import PurchaseOrderField from "./PurchaseOrderField";
import Comments from "./Comments";
import DateInputs from "./DateInputs";
import Accordion from "../../_molecules/accordion/Accordion";
import { SetDate } from "../../../types/invoices.type";

interface iDateDetailInputsProps {
  comments: string;
  dueDate: Date;
  endDate: Date;
  invoiceDate: Date;
  poNumber: string;
  setComments: (comments: string) => void;
  setDueDate: SetDate;
  setEndDate: SetDate;
  setInvoiceDate: SetDate;
  setPoNumber: (poNumber: string) => void;
  setStartDate: SetDate;
  startDate: Date;
}

const DateDetailInputs = ({
  comments,
  dueDate,
  endDate,
  invoiceDate,
  poNumber,
  setComments,
  setDueDate,
  setEndDate,
  setInvoiceDate,
  setPoNumber,
  setStartDate,
  startDate,
}: iDateDetailInputsProps) => (
  <Accordion title="Set Dates & Details" forceMount={true}>
    <div className="tw-grid tw-gap-8">
      <DateInputs
        invoiceDate={invoiceDate}
        setInvoiceDate={setInvoiceDate}
        dueDate={dueDate}
        setDueDate={setDueDate}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <div className="tw-grid tw-gap-y-4 sm:tw-grid-cols-2">
        <PurchaseOrderField poNumber={poNumber} setPoNumber={setPoNumber} />

        <Comments comments={comments} setComments={setComments} />
      </div>
    </div>
  </Accordion>
);

export default DateDetailInputs;
