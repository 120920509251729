import React, { useState } from "react"
import Modal from "../_molecules/modal/Modal";

const DeclineModal = ({ declineModalOpen, setDeclineModalOpen }) => {
  const [isButtonDisabled, setButtonDisabled] = useState(true);

  const handleSubmit = () => {
    setButtonDisabled(true) // this is just here because the linter was mad
  };

  return (
    <Modal
      open={declineModalOpen}
      setOpen={setDeclineModalOpen}
      onConfirm={handleSubmit}
      title="The title for this modal"
      disabled={isButtonDisabled}
    >
      <div>
        {/* WORDS ABOUT STAYING DELINKED GO HERE */}
      </div>
    </Modal>
  )
}

export default DeclineModal
