import React, { useEffect, useState } from "react";
import ProgressBar, { stepsType } from "@hui/_molecules/progress_bar/ProgressBar";

export default function OnboardingProgressBar({ progressSteps }: { progressSteps: stepsType }) {
  const [steps, setSteps] = useState(progressSteps);

  useEffect(() => {
    setSteps(progressSteps);
  }, [steps, progressSteps]);

  return (
    <>
      <ProgressBar classes="tw-hidden md:tw-block" steps={steps} vertical={false} />

      {/* Mobile View */}
      <ProgressBar classes="md:tw-hidden" steps={steps} />
    </>
  );
}
