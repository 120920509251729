import React from "react";
import { UkUserAccountDetails } from "../../../types/user.type";
import AccountDetailsShareModule from "../AccountDetailsShareModule";
import AccountDetailsCopyLineItem from "./CopyLineItem";
import AccountDetailsDisclaimer from "./AccountDetailsDisclaimer";

const UkAccountDetailsModule = ({
  currentUser,
}: {
  currentUser: UkUserAccountDetails;
}) => {
  const { accountName, accountNumber, accountType, bsb } = currentUser;

  const domesticDetails = [
    {
      label: "Account name",
      value: accountName,
      eventName: "dashboard_account_details_account_name_copied",
    },
    {
      label: "Account type",
      value: accountType,
      eventName: "dashboard_account_details_account_type_copied",
    },
    {
      label: "Sort code",
      value: bsb,
      eventName: "dashboard_account_details_bsb_number_copied",
    },
    {
      label: "Account number",
      value: accountNumber,
      eventName: "dashboard_account_details_account_number_copied",
    },
  ];

  return (
    <div className="tw-flex tw-flex-col tw-grow tw-gap-2">
      <div className="tw-flex tw-flex-col tw-mb-2 tw-px-2 tw-gap-1">
        {domesticDetails.map(({ label, eventName, value }) => (
          <AccountDetailsCopyLineItem
            key={label}
            label={label}
            eventName={eventName}
            value={value}
          />
        ))}
      </div>
      <div className="tw-mt-auto tw-mb-2 tw-px-2">
        <AccountDetailsDisclaimer international={false} />
      </div>
      <div>
        <AccountDetailsShareModule currentUser={currentUser} />
      </div>
    </div>
  );
};

export default UkAccountDetailsModule;
