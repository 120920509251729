import React, { useState, useEffect } from "react";

import FooterButtons from "../../invoice_quote/sections/FooterButtons";
import ScheduleInputs from "./ScheduleInputs";

import { useInvoiceQuoteContext } from "../../invoice_quote/InvoiceQuoteContext";
import InvoicePreviewModal from "../../invoice_quote/modals/InvoicePreviewModal";
import NotSendingWarning from "../NotSendingWarning";
import DateDetailInputs from "./DateDetailInputs";
import AdditionalInputs from "./AdditionalInputs";

const SettingsAndFooter = ({
  advancedSettingsOpen,
  depositsNotificationDismissed,
}) => {
  const [disabledSave, setDisabledSave] = useState(false);
  const [dateInvalidText, setDateInvalidText] = useState("");
  const [timeInvalidText, setTimeInvalidText] = useState("");

  const {
    client,
    comments,
    dueDate,
    endDate,
    invoiceClientSelected,
    invoiceDate,
    invoiceTotalIncludingSalesTax,
    otherProps,
    poNumber,
    recurrenceConfig,
    scheduleDate,
    scheduleTime,
    setComments,
    setDueDate,
    setEndDate,
    setInvoiceDate,
    setPoNumber,
    setRecurrenceConfig,
    setScheduleDate,
    setScheduleTime,
    setStartDate,
    startDate,
    status,
    setHidePhoneNumber,
    hidePhoneNumber,
    deposit,
    setDeposit,
  } = useInvoiceQuoteContext();

  useEffect(() => {
    if (
      dateInvalidText.length === 0 &&
      timeInvalidText.length === 0 &&
      invoiceTotalIncludingSalesTax >= 0
    ) {
      setDisabledSave(false);
    } else if (
      dateInvalidText.length > 0 ||
      timeInvalidText.length > 0 ||
      invoiceTotalIncludingSalesTax < 0
    ) {
      setDisabledSave(true);
    }
  }, [
    dateInvalidText,
    timeInvalidText,
    setDisabledSave,
    invoiceTotalIncludingSalesTax,
  ]);

  const openConfirmationModal = () => {
    $("#modal-invoice-email").modal("show");
  };

  const openPreviewModal = () => {
    $("#modal-preview-invoice").modal("show");
  };

  const handleSendNowClick = (event) => {
    event.preventDefault();
    setScheduleDate("");
    setScheduleTime("");
    openConfirmationModal();
    setDisabledSave(true);
  };

  const handleDateInvalidText = (value) => {
    setDateInvalidText(value);
  };

  const handleTimeInvalidText = (value) => {
    setTimeInvalidText(value);
  };

  return (
    <div data-testid="advanced-fields-ui">
      <NotSendingWarning
        client={client}
        invoiceClientSelected={invoiceClientSelected}
      />
      <ScheduleInputs
        dateInvalidText={dateInvalidText}
        invoiceDate={invoiceDate}
        scheduleDate={scheduleDate}
        scheduleTime={scheduleTime}
        setDateInvalidText={handleDateInvalidText}
        setDueDate={setDueDate}
        setScheduleDate={setScheduleDate}
        setScheduleTime={setScheduleTime}
        setTimeInvalidText={handleTimeInvalidText}
        recurrenceConfig={recurrenceConfig}
        setRecurrenceConfig={setRecurrenceConfig}
        timeInvalidText={timeInvalidText}
      />

      <DateDetailInputs
        comments={comments}
        dueDate={dueDate}
        endDate={endDate}
        invoiceDate={invoiceDate}
        poNumber={poNumber}
        setComments={setComments}
        setDueDate={setDueDate}
        setEndDate={setEndDate}
        setInvoiceDate={setInvoiceDate}
        setPoNumber={setPoNumber}
        setStartDate={setStartDate}
        startDate={startDate}
      />

      <AdditionalInputs
        defaultOpen={advancedSettingsOpen}
        deposit={deposit}
        depositsNotificationDismissed={depositsNotificationDismissed}
        hidePhoneNumber={hidePhoneNumber}
        setDeposit={setDeposit}
        setHidePhoneNumber={setHidePhoneNumber}
      />

      <hr />
      {disabledSave && (timeInvalidText || dateInvalidText) && (
        <div className="alert alert-info" role="alert">
          Schedule date is in the past. If you no longer wish to schedule the
          invoice you can&nbsp;
          <button className="hui-link tw-pt-0" onClick={handleSendNowClick}>
            <strong>send now</strong>
          </button>
        </div>
      )}
      <FooterButtons
        disabledSave={disabledSave}
        handleNextClick={openConfirmationModal}
        handlePreviewClick={openPreviewModal}
        isImpersonating={otherProps?.is_impersonating}
        invoiceStatus={status}
      />
      <InvoicePreviewModal disabledSave={disabledSave} />
    </div>
  );
};

export default SettingsAndFooter;
