export const RadioButtonGroupData = {
  options: [
    {
      name: "Share by email",
      description: "Use the Hnry app to email your account details to one or many clients",
      optionId: 1,
    },
    {
      name: "Copy to clipboard",
      description: "Copy your account details, to paste into a message to your clients",
      optionId: 2,
    },
    {
      name: "Download a deposit slip",
      description: "Get a formal PDF copy of your account details, to share with your clients",
      optionId: 3,
    },
  ],
};
