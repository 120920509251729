import React, { useState } from "react";
import Button from "../../_atoms/button/Button";
import Modal from "../../_molecules/modal/Modal";
import CardsTnCsText from "./CardsTnCsText";
import Scrollable from "../../_atoms/scrollable/Scrollable";

const introText = "Please review and accept the terms and conditions before continuing.";
const outroText = "By continuing you agree to the terms and conditions above.";

interface iCardsTnCs {
  cta: string;
  title: string;
  waitlistEnabled?: boolean;
}

const csrfToken: HTMLMetaElement | undefined = document.querySelector(
  "meta[name='csrf-token']",
);

const getUrl = async (url: string) => fetch(url, {
  method: "POST",
  headers: {
    "X-CSRF-Token": csrfToken?.content || "",
    "Content-Type": "application/json",
  },
  credentials: "same-origin",
});

const acceptTerms = async () => {
  const response = await getUrl(Routes.join_waitlist_cards_path());
  if (!response.ok) {
    throw new Error("Unable to join waitlist. Please try again later");
  }

  const data = await response.json();

  if (response.status === 409) {
    throw new Error(data.message);
  }
  return data.redirect_url;
};

const applyForCard = async () => {
  await getUrl(Routes.apply_cards_path());
};

const CardsTnCs = ({
  cta,
  title,
  waitlistEnabled,
}: iCardsTnCs) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasRead, setHasRead] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onAccepted = async () => {
    setIsLoading(true);
    if (waitlistEnabled) {
      try {
        const redirectUrl = await acceptTerms();
        window.location = redirectUrl;
      } catch (e) {
        if (toastr) { toastr.error(e.message); }
      }
    } else {
      try {
        await applyForCard();
        window.location.reload();
      } catch (e) {
        if (toastr) { toastr.info(e.message); }
      }
    }

    setIsLoading(false);
    setIsOpen(false);
  };

  const onContentSeen = () => {
    setHasRead(true);
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="primary"
        dataTrackClick={{ eventName: "card_join_the_waitlist_btn_clicked" }}>
        {cta}
      </Button>
      <Modal
        confirmCTA={cta}
        disabled={!hasRead}
        double={true}
        loading={isLoading}
        onConfirm={onAccepted}
        open={isOpen}
        setOpen={setIsOpen}
        title={title}
      >
        <p className="tw-text-xs sm:tw-text-sm tw-mb-2">{introText}</p>
        <div className="tw-mb-4">
          <Scrollable onContentSeen={onContentSeen}>
            <CardsTnCsText />
          </Scrollable>
        </div>
        <p className="tw-text-xs sm:tw-text-sm tw-mb-1">{outroText}</p>
      </Modal>
    </>
  );
};

export default CardsTnCs;
