import React from "react";
import PropTypes from "prop-types";

import { InvoiceQuoteContext } from "../invoice_quote/InvoiceQuoteContext";
import LineItems from "./line_items/LineItems";
import ClientReimbursments from "../invoice_quote/sections/ClientReimbursments";
import Subtotals from "../invoice_quote/sections/Subtotals";
import HiddenFields from "../invoice_quote/sections/HiddenFields";
import InvoiceEmailModal from "../invoice_quote/modals/InvoiceEmailModal";
import ContextLoader from "./ContextLoader";
import LegacySettingsAndFooter from "./SettingsAndFooter";
import SettingsAndFooter from "./settingsAndFooter/SettingsAndFooter";

import { timeZoneType } from "../../types";
import { invoiceFormTextType } from "../../types/invoices";
import { hasFeatureFlag } from "../../utilities/feature_flags";

const InvoiceForm = (props) => {
  const {
    client_id,
    client,
    defaultDueDateDays,
    deposits_notification_dismissed,
    invoice,
    invoice_client_selected,
    invoice_currency_options,
    is_impersonating,
    lineItemsAttributesName,
    model,
    client_name,
    status,
    text,
    timeZone,
    trading_name,
    user_name,
    show_updated_advanced_options_section_ui
  } = props;

  const timeZoneWithBrowsertime = {
    ...timeZone,
    browserInTimezone:
      Intl.DateTimeFormat().resolvedOptions().timeZone ===
        timeZone.tzdataName || false,
  };

  const hasAdvancedFieldsUI = hasFeatureFlag("invoice_advanced_fields_ui");

  const FFSettingsAndFooter = hasAdvancedFieldsUI ? (
    <SettingsAndFooter
      advancedSettingsOpen={!!parseFloat(invoice.deposit)}
      client={client}
      currencySymbol={invoice_currency_options.symbol}
      defaultDueDateDays={defaultDueDateDays}
      depositsNotificationDismissed={deposits_notification_dismissed}
      invoiceClientSelected={invoice_client_selected}
      invoiceStatus={status}
      isImpersonating={is_impersonating}
    />
  ) : (
    <LegacySettingsAndFooter
      advancedSettingsOpen={!!parseFloat(invoice.deposit)}
      client={client}
      currencySymbol={invoice_currency_options.symbol}
      defaultDueDateDays={defaultDueDateDays}
      depositsNotificationDismissed={deposits_notification_dismissed}
      invoiceClientSelected={invoice_client_selected}
      invoiceStatus={status}
      isImpersonating={is_impersonating}
      showUpdatedAdvancedOptionsSection={show_updated_advanced_options_section_ui}
    />
  );

  return (
    <InvoiceQuoteContext {...props}>
      <ContextLoader {...props} />
      <div className="tw-mt-4">
        <h2 className="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-900 tw-mb-8 md:tw-hidden">
          Invoice for: <span className="tw-font-normal">{client}</span>
        </h2>
        <div className="tw-mb-12">
          <div className="tw-mb-4">
            <h2 className="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-800 !tw-mb-0">
              {text.invoice_items}
            </h2>
            <LineItems
              lineItemsAttributesName={lineItemsAttributesName}
              model={model}
            />
          </div>
          <div className="tw-mb-8">
            <ClientReimbursments
              clientId={client_id}
              showAddNewButton={status !== "SENT"}
            />
          </div>
          <div className="tw-mb-12">
            <Subtotals
              currencyOptions={invoice_currency_options}
              hidePriorDeduction
              model={model}
              status={status}
            />
          </div>
        </div>
        {FFSettingsAndFooter}
        <InvoiceEmailModal
          client={client}
          clientName={client_name}
          invoiceClientSelected={invoice_client_selected}
          timeZone={timeZoneWithBrowsertime}
          tradingName={trading_name}
          userName={user_name}
        />
        <HiddenFields />
      </div>
    </InvoiceQuoteContext>
  );
};

InvoiceForm.propTypes = {
  defaultDueDateDays: PropTypes.number.isRequired,
  timeZone: timeZoneType,
  text: invoiceFormTextType,
};

export default InvoiceForm;
