import React, { useState } from "react";
import I18n from "../../utilities/translations";
import Modal from "../_molecules/modal/Modal";
import ConsentCheckbox from "../_molecules/consent_checkbox/ConsentCheckbox"
import ConsentToAct from "../consents/consent_to_act/ConsentToAct";
import updateTaxAgencyAuthorisation from "../../API/tax_agency_authorisation.api";
import { taxAgencyAuthorisation as taa } from "../../types/taxAgencyAuthorisation.type";

interface iGrantAuthorityModal {
  grantAuthorityModalOpen: boolean;
  setGrantAuthorityModalOpen: (boolean) => void;
  setShowAlert: (boolean) => void;
  taxAgencyAuthorisation: taa;
}

const GrantAuthorityModal = ({ grantAuthorityModalOpen, setGrantAuthorityModalOpen, setShowAlert, taxAgencyAuthorisation }: iGrantAuthorityModal) => {
  const [taxAuthorityGranted, setTaxAuthorityGranted] = useState(false);

  const handleSubmit = async () => {
    try {
      const response = await updateTaxAgencyAuthorisation({ id: taxAgencyAuthorisation.id, taxAuthorityGranted });

      if (response.success) {
        setGrantAuthorityModalOpen(false);
        setShowAlert(false)
        toastr.success(response.message);
      } else {
        toastr.error(response.message);
      }
    } catch (error) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("User unable to update tax agency authorisation", { error });
      }
    }
  };

  const ctx = "home.call_to_action.delinked.grant_authority"
  const taxAgencyName = I18n.t("global.tax_collection_authority_short");
  const grantAuthorityModalTitle = I18n.t("title", { tax_agency: taxAgencyName, scope: ctx })
  const grantAuthorityModalParagraph = I18n.t("paragraph", { tax_agency: taxAgencyName, scope: ctx })

  return (
    <Modal
      closable={true}
      disabled={!taxAuthorityGranted}
      double={true}
      onConfirm={handleSubmit}
      open={grantAuthorityModalOpen}
      setOpen={setGrantAuthorityModalOpen}
      title={grantAuthorityModalTitle}
      confirmCTA="Authorise Hnry as tax agent"
    >
      <ConsentToAct />
      <ConsentCheckbox
        id="tax_authority_granted"
        name="tax_authorigy_granted"
        label={grantAuthorityModalParagraph}
        onChange={setTaxAuthorityGranted}
      />
    </Modal>
  )
}

export default GrantAuthorityModal
