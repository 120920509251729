import React, { useState } from "react";
import Confetti from "../confetti";

interface iConfettiPaymentConfirmedScreen {
  taxesImagePath: string,
  transferImagePath: string,
  feeImagePath: string
  demonstrationPayAmount: string
}

function ConfettiPaymentConfirmedScreen({ 
  taxesImagePath,
  transferImagePath,
  feeImagePath,
  demonstrationPayAmount,
}: iConfettiPaymentConfirmedScreen) {
  const [areValueItemsVisible, setAreValueItemsVisible] = useState(false);

  return (
    <Confetti
      onAnimationComplete={() => setAreValueItemsVisible(true)}
      title="You just got paid!"
    >
      {areValueItemsVisible && (
        <div>
          <div className="paid-value">
            <img src={taxesImagePath} className="paid-value-img" alt="Calculator"/>
            <p className="my-1">
              We automatically calculated, and deducted all your taxes on your&nbsp;
              {demonstrationPayAmount}
            </p>
          </div>

          <div className="paid-value">
            <img src={transferImagePath} className="paid-value-img" alt="Piggy bank" />
            <p className="my-1">
              We transferred the remainder to your personal bank account
            </p>
          </div>

          <div className="paid-value">
            <img src={feeImagePath} className="paid-value-img" alt="Donut graph" />
            <p className="my-1">
              We even claimed our 1% fee as a business expense for you
            </p>
          </div>
        </div>
      )}
    </Confetti>
  );
}

export default ConfettiPaymentConfirmedScreen;
