export const tableSampleData = [
  {
    category: "Advertising",
    date: "15/06/2023",
    price: "$1,500.00",
    description: "Digital advertising for 1/4th June: Google, Facebook ads",
  },
  {
    category: "Mobile phones",
    date: "1/06/2023",
    price: "$60.00",
    description: "Mobile phone for work use",
  },
  {
    category: "Printing & stationery",
    date: "21/05/2023",
    price: "$43.29",
    description: "Office supplies - pens, markers etc",
  },
  {
    category: "Rent - External space hire",
    date: "01/05/2023",
    price: "$800.00",
    description: "Office rent for April 2023",
  },
  {
    category: "Mobile phones",
    date: "01/05/2023",
    price: "$60.00",
    description: "Work phone for May 2023",
  },
  {
    category: "Advertising",
    date: "15/01/2023",
    price: "$2,150.00",
    description: "Digital ads from Jan - June",
  },
];
