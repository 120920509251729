import React, { useState } from "react";
import Modal from "../../_molecules/modal/Modal";
import I18n from "../../../utilities/translations";
import Icon from "../../_atoms/icons/icon/Icon";
import Button from "../../_atoms/button/Button";
import Input from "../../_atoms/input/Input";
import SCAConfirm from "../sca_confirm/SCAConfirm";

type fieldType = {
  label: string,
  value: string
}

interface iCOPModal {
  fields: fieldType[];
  actionName: string;
}

const COPModal = ({ fields, actionName }: iCOPModal) => {
  const [show, setShow] = useState(true);
  const [showSCA, setShowSCA] = useState(false);


  // TODO: Fix me
  const handleContinueClick = async () => {
    setShow(false)
    setShowSCA(true);
  }

  const iconComponent = () => (
    <span className="hui-sca__header-icon tw-bg-amber-100">
      <Icon type="ExclamationTriangleIcon" classes="!tw-text-amber-700 tw-w-6 tw-h-6" />
    </span>
  );

  return (
    <>
      <Modal
        open={show}
        setOpen={setShow}
        title={I18n.t("cop.warning_title")}
        includesFooter={false}
        extraHeaderContent={iconComponent()}
        id="cop-modal"
        modalClasses="hui-sca"
      >
        <div className="tw-flex tw-justify-between tw-flex-col">
          <p className="!tw-text-gray-700 tw-mb-4">{I18n.t("cop.warning_subheading")}</p>
          {fields.map(({ label, value }) => (
            <Input
              inputClasses="tw-mb-4"
              key={`${label}-field`}
              disabled
              readOnly
              label={label}
              value={value}
            />
          ))}
          <div className="tw-flex tw-flex-col tw-gap-y-3 tw-m-1">
            <Button
              onClick={handleContinueClick}
              variant="primary"
              >
              {I18n.t("cop.continue_button")}
            </Button>
            <Button onClick={() => setShow(false)} variant="secondary">
              {I18n.t("cop.edit_button")}
            </Button>
          </div>
        </div>
      </Modal>
      {showSCA && (
        <SCAConfirm
          actionName={actionName}
          deviceName="Samsung" // TODO: fix me
          challengeId="1" // TODO: fix me
        />
      )}
    </>
  );
}

export default COPModal;
