import React, { useState, useCallback, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  KeyboardSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import ModuleItem from "./ModuleItem";
import SortableItem from "./SortableItem";
import Button from "../_atoms/button/Button";
import { updateDashboardSettings, getDashboardSettings } from "../../API/dashboard.api";
import Loader from "../inputs/_elements/loader";
import I18n from "../../utilities/translations";

const Content = () => {
  const [items, setItems] = useState([]);
  const [activeId, setActiveId] = useState<string | null>(null); // The module that has been dragged
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [moduleData, setModuleData] = useState<object>({});

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Fetch the current module orders
  useEffect(() => {
    const fetchData = async () => {
      const response = await getDashboardSettings();
      const { data, ordered } = response;
      setModuleData(data);
      setItems(ordered);
    };

    try {
      fetchData();
    } catch (err) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("Unable to retrieve data for dashboard settings");
      }
    }
  }, []);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  }, []);

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((itms) => {
        const oldIndex = itms.indexOf(active.id.toString());
        const newIndex = itms.indexOf(over!.id.toString());

        return arrayMove(itms, oldIndex, newIndex);
      });
    }
    setDisabledSubmit(false);
    setActiveId(null);
    window.unsaved_changes = true;
    window.analytics.track("dashboard_settings_module_dragged");
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const resetSubmit = () => {
    setDisabledSubmit(false);
    setSubmitting(false);
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDisabledSubmit(true);
    setSubmitting(true);
    window.unsaved_changes = false;
    window.analytics?.track("dashboard_settings_save_btn_clicked");

    try {
      const response = await updateDashboardSettings({ items });
      const { message, code } = response;

      if (code === "ok") {
        // escape() which is not longer support
        // eslint-disable-next-line xss/no-location-href-assign
        window.location.href = encodeURI(Routes.root_path());
      } else {
        toastr.error(message);
        resetSubmit();
      }
    } catch (error) {
      if (typeof Rollbar !== "undefined") {
        Rollbar.warning("User unable to save dashboard settings", { error });
      }
      toastr.error(I18n.t("dashboard_settings.catch_error_message"));
      resetSubmit();
    }
  }

  return (
    <>
      {items.length > 0 ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={items}
            strategy={rectSortingStrategy}
          >
            {/* The static module list */}
            <ol className="dashboard-modules-container">
              {items.map((id) =>
                <li key={`sortable-item-${id}`}>
                  <SortableItem moduleData={moduleData} key={`sortable-item-${id}`} id={id} dashedBorder={activeId === id} />
                </li>
              )}
            </ol>
          </SortableContext>

          {/* The overlay when a module is dragging */}
          <DragOverlay adjustScale>
            {activeId ? <ol><li><ModuleItem id={activeId} moduleData={moduleData} /></li></ol> : null}
          </DragOverlay>
        </DndContext>
      ) : <div className="tw-flex tw-justify-between tw-flex-col tw-items-center tw-my-32 sm:tw-my-80 tw-min-h-[20rem]"><Loader /></div>}


      {/* Footer */}
      <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row-reverse tw-justify-between tw-mt-8">
        <Button
          disabled={disabledSubmit}
          onClick={handleSubmit}
          loading={submitting}
        >
          Save dashboard layout
        </Button>
        <a
          className="hnry-button hnry-button--secondary !tw-hidden md:!tw-block"
          href={Routes.root_path()}
          data-track-click={JSON.stringify({
            eventName: "dashboard_settings_cancel_btn_clicked",
            data: { ordered: items },
          })}
        >
          Cancel
        </a>
      </div>
    </>
  );
}

export default Content;
