import React, { useState } from "react";
import Alert from "../_molecules/alert/Alert";
import Button from "../_atoms/button/Button"
import DeclineModal from "./DeclineModal";
import I18n from "../../utilities/translations";
import GrantAuthorityModal from "./GrantAuthorityModal";
import { taxAgencyAuthorisation as taa } from "../../types/taxAgencyAuthorisation.type";

const ctx = "home.call_to_action.delinked";
const taxAgencyName = I18n.t("global.tax_collection_authority_short");

const DelinkedAlert = ({ taxAgencyAuthorisation }: { taxAgencyAuthorisation: taa }) => {
  const [showAlert, setShowAlert] = useState(true)
  const [grantAuthorityModalOpen, setGrantAuthorityModalOpen] = useState(false)
  const [declineModalOpen, setDeclineModalOpen] = useState(false)

  return (
    <>
      <Alert
        variant="danger"
        title={I18n.t("title", { tax_agency: taxAgencyName, scope: ctx })}
        show={showAlert}
      >
        <p>
          {I18n.t("paragraph", { tax_agency: taxAgencyName, scope: ctx })}
        </p>

        <div className="tw-flex tw-gap-4 tw-flex-col sm:tw-flex-row">
          <Button type="button" onClick={() => setGrantAuthorityModalOpen(true)}>
            Grant Authority
          </Button>
          <Button type="button" variant="tertiary" onClick={() => setDeclineModalOpen(true)} >
            Decline
          </Button>
        </div>
      </Alert>
      <GrantAuthorityModal
        grantAuthorityModalOpen={grantAuthorityModalOpen}
        setGrantAuthorityModalOpen={setGrantAuthorityModalOpen}
        taxAgencyAuthorisation={taxAgencyAuthorisation}
        setShowAlert={setShowAlert}
      />
      <DeclineModal
        declineModalOpen={declineModalOpen}
        setDeclineModalOpen={setDeclineModalOpen}
      />
    </>
  );
};

export default DelinkedAlert
