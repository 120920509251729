const sampleData = [
  {
    value: "in_total",
    name: "In total",
  },
  {
    value: "weekly",
    name: "Weekly",
  },
  {
    value: "monthly",
    name: "Monthly",
  },
];

export default sampleData;
