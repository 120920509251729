import React, { useCallback } from "react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Logo from "../../../../assets/images/hnry-logo-name-only-inverted.svg";

export interface iHeaderBarProps {
  pageTitle: string;
}

const Headerbar = ({ pageTitle }: iHeaderBarProps) => {
  // A bad hack we need to live with until we get rid of the old Nav
  // This is because the old Nav uses an image tag for the page title which we cannot use in react.
  const isShowingLogo = useCallback(
    () => pageTitle.trim().startsWith("img", 1),
    [pageTitle],
  );

  return (
    <div
      className="tw-flex tw-h-12 tw-shrink-0 tw-items-center tw-gap-x-4 tw-text-white tw-bg-brand tw-px-4 tw-shadow-sm sm:tw-gap-x-6 sm:tw-px-6 lg:tw-px-8"
    >
      <button
        type="button"
        className="-tw-m-1.5 tw-p-1.5 tw-text-white lg:tw-hidden"
        onClick={() => {
          document.dispatchEvent(new Event("hnry:openNavSidebar"));
        }}
      >
        <span className="tw-sr-only">Open sidebar</span>
        <Bars3Icon className="tw-h-7 tw-w-7" aria-hidden="true" />
      </button>

      <div className="tw-text-center tw-flex-1 tw-mr-12">
        {isShowingLogo() ? (
          <img className="tw-h-12 tw-inline-block" src={Logo} alt="Hnry Logo" />
        ) : (
          pageTitle
        )}
      </div>
    </div>
  );
};

export default Headerbar;
