import React from "react";
import CardsTnCs from "../_organisms/cards_tncs/CardsTnCs";

interface iCardModuleCTA {
  activeStatus: boolean;
  waitlistEnabled: boolean;
  hasDeactivatedCard: boolean;
}

const CardModuleCTA = ({ activeStatus, waitlistEnabled, hasDeactivatedCard }: iCardModuleCTA) => {
  const cannotCreate = () => {
    if(toastr) {
      toastr.error("Failed to create card - Please reach out to our team")
    }
  }

  if (activeStatus && !hasDeactivatedCard) {
    return (
      <CardsTnCs cta="Activate your Hnry card" title="Hnry Debit Card Terms and Conditions" waitlistEnabled={waitlistEnabled} />
    );
  }

  if (hasDeactivatedCard) {
    return (
      <button className="hnry-button hnry-button--primary tw-w-fit" onClick={cannotCreate}>
        Activate your Hnry card
      </button>
    )
  }
  return null;
};

export default CardModuleCTA;
