import React from "react";
import DepositFields from "./DepositFields";
import HidePhoneNumberField from "./HidePhoneNumberField";
import Accordion from "../../_molecules/accordion/Accordion";

interface iAdditionalInputsProps {
  defaultOpen: boolean;
  deposit: number;
  depositsNotificationDismissed: boolean;
  hidePhoneNumber: boolean;
  setDeposit: (value: number) => void;
  setHidePhoneNumber: (value: boolean) => void;
}

const AdditionalInputs = ({
  defaultOpen,
  deposit,
  depositsNotificationDismissed,
  hidePhoneNumber,
  setDeposit,
  setHidePhoneNumber,
}: iAdditionalInputsProps) => (
  <Accordion
    title="Additional Settings"
    defaultOpen={defaultOpen}
    forceMount={true}
  >
    <div className="tw-grid tw-gap-4">
      <DepositFields
        isAlertDismissed={depositsNotificationDismissed}
        deposit={deposit}
        setDeposit={setDeposit}
      />
      <HidePhoneNumberField
        hidePhoneNumber={hidePhoneNumber}
        setHidePhoneNumber={setHidePhoneNumber}
      />
    </div>
  </Accordion>
);

export default AdditionalInputs;
