const TabsLinkData = [
  {
    name: "Profile",
    url: "#",
    active: true,
  },
  {
    name: "Financial",
    url: "#",
    active: false,
  },
  {
    name: "Branding",
    url: "#",
    active: false,
  },
  {
    name: "Security",
    url: "#",
    active: false,
  },
];
const TabsButtonData = [
  {
    name: "Domestic",
    active: true,
  },
  {
    name: "International",
    active: false,
  },
];

export { TabsLinkData, TabsButtonData };
