import React, { useState } from "react";
import { postJson } from "../../../API/config/fetch.api";
import AuthModal from "./AuthModal";
import Icon from "../../_atoms/icons/icon/Icon";
import Modal from "../../_molecules/modal/Modal";
import InputCopy from "../../_atoms/input/InputCopy";
import Input from "../../_atoms/input/Input";
import Alert from "../../_molecules/alert/Alert";

interface iCardType {
  card_name: string;
  card_number: string;
  expiry_date: string;
}
export interface iCardDetails {
  userPhoneNumber: string;
  userEmail: string;
  otpRequiredForLogin: boolean;
}

const CardDetails = ({
  otpRequiredForLogin,
  userPhoneNumber,
  userEmail,
}: iCardDetails) => {
  const [cardDetails, setCardDetails] = useState<iCardType | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const sendSMS = () => {
    postJson(Routes.send_card_details_cards_path(), undefined).then(() => {
      toastr.success(`Details sent via SMS to *** *** ${userPhoneNumber.slice(-4)}`, { timeOut: 10000 });
    }).catch(() => {
      toastr.error("SMS failed to send");
    });
  };

  const handleClick = () => {
    setModalOpen(true);
  };


  const cardDetailsDisplay = (cardNumber: string) => `${cardNumber.slice(0, 4)} ${cardNumber.slice(4, 8)} ${cardNumber.slice(8, 12)} ${cardNumber.slice(12, 16)}`

  return (
    <>
      <button className="hnry-button hnry-button--link" onClick={handleClick}>
        <Icon type="EyeIcon" classes="!tw-mx-0" />
        <span className="tw-block tw-text-xs tw-pt-2">Reveal</span>
      </button>

      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        confirmCTA={null}
        cancelCTA={null}
        title={cardDetails ? "Your card details" : "Let us know it's you"}
        closable>
        { !cardDetails
          ? <AuthModal authenticatorApp={otpRequiredForLogin} setCardDetails={setCardDetails} userEmail={userEmail} sendSMS={sendSMS} />
          : <>
              <InputCopy label="Card name" name="cardName" value={cardDetails?.card_name}  />
              <Input label="Card number" name="cardNumber" value={cardDetailsDisplay(cardDetails?.card_number)}  />
              <div className="tw-flex tw-flex-row tw-gap-8 tw-my-3">
                <div className="tw-flex-grow">
                  <InputCopy label="Exp:" name="expiry" value={cardDetails?.expiry_date} />
                </div>
                <div className="tw-flex-grow">
                  <Input label="CVV:" name="cvv" disabled value="***" />
                </div>
              </div>

              <div className="tw-mb-8">
                <Alert variant='info' includesIcon={false}>For security purposes, remaining card numbers and CVV will be send via SMS.</Alert>
              </div>
              <button className="hnry-button hnry-button--tertiary tw-w-full" onClick={sendSMS}>Resend SMS</button>
            </>
        }
      </Modal>
    </>
  );
};

export default CardDetails;
