
import React from "react";
import MenuItem from "@hui/_molecules/dropdowns/menu/MenuItem";
import { User } from "app/javascript/global";
import I18n from "../../../utilities/translations";

const IntercomNavigation = (
    isNative: boolean,
    currentUser: User,
) => {
    if (isNative || typeof Intercom === "undefined") {
        const href = isNative
            ? "/app/open_intercom"
            : `mailto:${currentUser.jurisdiction.supportEmail}`;

        return (
            <MenuItem label={I18n.t("home.uk_waitlist.more_actions.leave_waitlist")} href={href} />
        );
    }
    return (
        <MenuItem
            label={I18n.t("home.uk_waitlist.more_actions.leave_waitlist")}
            asButton={true}
            onClick={() => {
                Intercom("show");
            }}
        />
    );
};

export default IntercomNavigation