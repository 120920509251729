import React from "react";
import Datepicker, {
  DatepickerInputProps,
} from "../../inputs/datepicker/datepicker";
import I18n from "../../../utilities/translations";
import { SetDate } from "../../../types/invoices.type";

interface iDateBlock extends DatepickerInputProps {
  label: string;
}

interface iDateInputs {
  invoiceDate: Date;
  setInvoiceDate: SetDate;

  dueDate: Date;
  setDueDate: SetDate;

  startDate: Date;
  setStartDate: SetDate;

  endDate: Date;
  setEndDate: SetDate;
}

export const DateBlock = ({ label, ...inputProps }: iDateBlock) => (
  <Datepicker legacyStyles={false} {...{ label, inputProps }} />
);

const ctx = { scope: "invoices.form" };

const DateInputs = ({
  invoiceDate,
  setInvoiceDate,
  dueDate,
  setDueDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: iDateInputs) => (
  <div className="tw-grid tw-gap-x-8 tw-gap-y-4 sm:tw-grid-cols-2">
    <DateBlock
      label={I18n.t("invoice_date", ctx)}
      value={invoiceDate}
      name="invoice[invoice_date]"
      onChange={(date) => setInvoiceDate(date)}
    />
    <DateBlock
      label={I18n.t("due_date", ctx)}
      value={dueDate}
      name="invoice[due_date]"
      onChange={(date) => setDueDate(date)}
    />

    <DateBlock
      label={I18n.t("period_start_date", ctx)}
      value={startDate}
      name="invoice[period_start_date]"
      onChange={(date) => setStartDate(date)}
    />
    <DateBlock
      label={I18n.t("period_end_date", ctx)}
      value={endDate}
      name="invoice[period_end_date]"
      onChange={(date) => setEndDate(date)}
    />
  </div>
);

export default DateInputs;
