import React from "react";

// Currently only returning NZ terms, as delinking only needs NZ at the moment

const ConsentToAct = () => (
  <div className="list-circle tw-prose tw-pb-2">
    <p>By agreeing to Hnry’s terms and conditions, I authorise any employees or contractors from Hnry Ltd. (NZC 6251417) or any subsidiary company (Hnry)
      to act on my behalf for the taxes detailed below with Inland Revenue (IR) and the Accident Compensation Corporation (ACC). </p>
    <p>This authority to act will remain in place until the service agreement is terminated,
      per Section B, Part 8 of the <a href="https://hnry.co.nz/agreement" target="_blank" rel="noreferrer">Hnry Service Agreement</a>.
      At this time Hnry will delink from your IR and ACC and no longer act on your behalf. </p>
    <p>This includes the authority to:</p>
    <ul className="pl-3">
      <li>Obtain my information from IR through all channels, including Inland Revenue’s online services.</li>
      <li>
        <p>Act on my behalf to obtain information for ALL tax types in IR, including but not limited to:</p>
        <ul className="pl-3">
          <li>Income Tax and Associated Tax Types</li>
          <li>Goods and Services Tax</li>
          <li>Fringe Benefit Tax</li>
          <li>PAYE, Student Loan, Withholding Tax Deductions</li>
          <li>Approved Issuer Levy</li>
          <li>Working for Family Tax Credits</li>
          <li>Gaming Machine Duty</li>
          <li>Interest PAYE</li>
          <li>Non Resident Withholding Tax</li>
          <li>Resident Withholding Tax and Dividends</li>
          <li>Personal Tax Rebate</li>
          <li>Imputation Credit Account</li>
          <li>Specified Superannuation Contribution Withholding Tax</li>
          <li>ACC Compensation Levies (all types)</li>
          <li>Withholding Tax Deductions</li>
          <li>Student Loan Borrower</li>
        </ul>
      </li>
      <li>Have full access to information held by IR and the ability to modify my details relating to the tax accounts that they are linked to.</li>
      <li>Prepare, submit and sign tax returns with IR on my behalf.</li>
      <li>Make enquiries of the IR from time to time regarding my tax affairs, verbally or in writing with the Inland Revenue.</li>
      <li>To query and change Client information held with IR through IR staff, and via online services provided by IR.</li>
      <li>Act on my behalf as agent for ACC levy purposes.</li>
      <li>Obtain my information from ACC through all channels, including ACC’s online services. </li>
      <li>Have full access to information held by ACC and the ability to modify my details on my ACC levy accounts on any channel.</li>
      <li>All correspondence from IR and ACC will be directed to me, apart from correspondence pertaining to GST and ACC which will be directed to Hnry.</li>
    </ul>
  </div>
)

export default ConsentToAct
