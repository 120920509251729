import React, { ReactNode } from "react";
import DOMPurify from "dompurify";
import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { sizeClasses, IconSizes } from "../icons/icon/Icon";
import "tippy.js/dist/tippy.css";
import "./styles.scss";

export type Placement = "top" | "bottom" | "left" | "right";
export type Trigger = "mouseenter focus" | "click" | "focusin" | "mouseenter click" | "manual" | "mouseenter click focus";
export interface iTooltip {
  /*
  * The position of the popover
  */
  placement?: Placement;
  /*
  * The content of the popover
  */
  popoverMessage: string;
  /*
  * https://github.com/atomiks/tippyjs-react#component-children
  */
  children?: ReactNode;
  /*
  * Hide the popover when click outside it
  */
  hideOnClick?: boolean;
  /*
  * Determines the events that cause the popover to show. Multiple event names are separated by spaces.
  */
  trigger?: Trigger;
  /*
  * Invoked when the tooltip begins to transition in
  */
  onShow?: () => void;
  /*
  * The size of the tooltip icon
  */
  size?: IconSizes;
  /*
  * The learn more link that append after the popover message
  */
  learnMore?: string;

  /*
  * Classes to apply to the popper
  */
  popperClasses?: string;
}

const sanitizedData = (data) => ({
  // eslint-disable-next-line xss/no-mixed-html
  __html: DOMPurify.sanitize(data, { USE_PROFILES: { html: true }})
});

const content = (text: string, learnMore?: string, popperClasses?: string) => (
  <>
    <div className={classNames("hui-tooltip__popper", popperClasses)}>
      <span dangerouslySetInnerHTML={sanitizedData(text)} />
      {learnMore
        && <a className="hui-tooltip__popper-link" href={learnMore} target="_blank" rel="noreferrer">Learn more</a>}
    </div>
  </>
);

const Tooltip = ({
  popoverMessage,
  popperClasses,
  placement = "top",
  children = null,
  hideOnClick = true,
  trigger = "mouseenter click",
  onShow = undefined,
  size = "lg",
  learnMore = "",
}: iTooltip) => (
  <Tippy
    className="hui-tooltip-wrapper"
    placement={placement}
    interactive
    arrow
    content={content(popoverMessage, learnMore, popperClasses)}
    hideOnClick={hideOnClick}
    maxWidth="250px"
    trigger={trigger}
    offset={[0, 8]}
    onShow={onShow}
  >
    {children ? <div className="hover:tw-cursor-pointer">{children}</div> : (
      <div className="hui-tooltip" role="tooltip">
        <QuestionMarkCircleIcon
          className={classNames("hover:tw-cursor-pointer hover:tw-text-gray-900", { [`${sizeClasses[size]}`]: size })}
          aria-hidden="true"
        />
        <span className="tw-sr-only">Launch tooltip</span>
      </div>
    )}
  </Tippy>
);

export default Tooltip;
