import React from "react";

const CardsTnCsText = () => (
  <div className="tw-prose tw-prose-sm tw-prose-gray !tw-max-w-full">
    <h1 className="hnry-heading !tw-text-base sm:!tw-text-lg">
      Hnry Digital Card Terms and Conditions, August 2023
    </h1>
    <h2 className="hnry-heading !tw-text-sm sm:!tw-text-base">Content</h2>
    <ul>
      <li>Definitions</li>
      <li>What this Terms and Conditions Covers</li>
      <li>General</li>
      <li>About the VISA Debit Card</li>
      <li>About The Digital Wallet</li>
      <li>About the Balance</li>
      <li>Lost, Stolen or Compromised Devices</li>
      <li>Fees &amp; Charges</li>
      <li>Disputed Transactions</li>
      <li>VISA Debit Card Limit</li>
    </ul>
    <h3 className="hnry-heading hnry-heading--h3">Definitions</h3>
    <dl>
      <div className="tw-mb-4">
        <dt className="tw-inline">Apple Pay</dt>&nbsp;
        <dd className="tw-inline">
          means a Digital Wallet Service provided by Apple
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">Card Fund</dt>&nbsp;
        <dd className="tw-inline">
          means the positive value of money recorded by the Issuer as being
          available for transactions using the Card.
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">Cardholder</dt>&nbsp;
        <dd className="tw-inline">
          means the holder of the VISA Debit Card added to the Digital Wallet
          Service on that holder&rsquo;s Device.
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">Digital Wallet Services</dt>&nbsp;
        <dd className="tw-inline">
          means any of Apple Pay or Google Pay branded digital wallet
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">Google Pay </dt>&nbsp;
        <dd className="tw-inline">
          means a Digital Wallet Service provided by Google
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">Issuer</dt>&nbsp;
        <dd className="tw-inline">
          means Flexewallet Pty Ltd ABN 16 164 657 032 AFSL 448066, a wholly
          owned subsidiary of Novatti Pty Ltd.
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">PIN </dt>&nbsp;
        <dd className="tw-inline">
          means the Personal Identification Number required to be entered by you
          when using the Card for electronic transactions.
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">VISA Debit Card (&ldquo;Card&rdquo;)</dt>
        &nbsp;
        <dd className="tw-inline">means the Hnry&rsquo;s VISA Debit Card.</dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">VISA </dt>&nbsp;
        <dd className="tw-inline">means VISA Inc.</dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">You or your or Cardholder</dt>&nbsp;
        <dd className="tw-inline">
          means the person to whom the Card is issued.
        </dd>
      </div>
      <div className="tw-mb-4">
        <dt className="tw-inline">We or Us</dt>&nbsp;
        <dd className="tw-inline">means Hnry.</dd>
      </div>
    </dl>
    <h2 className="hnry-heading hnry-heading--h2">
      What this Terms and Conditions covers
    </h2>
    <p>
      These are the terms and conditions (T&amp;Cs) on which we provide the Card
      to you.
    </p>
    <p>
      You agree to the Terms and conditions. By applying for, or using, your
      Card, you confirm that you accept and agree to this T&amp;Cs (including
      any amendments and additions to this T&amp;Cs at the time you apply as
      published at the Hnry App or Hnry Website). By your continued use of the
      Card, you accept and agree to any future changes to these T&amp;Cs,
      whether or not reviewed by you, and which shall constitute your consent
      and acceptance of the revised T&amp;Cs.
    </p>
    <p>
      We are not responsible for Card transactions that are processed as a
      result of an indirect or direct breach of these Terms and Conditions.
    </p>
    <h3 className="hnry-heading hnry-heading--h3">General</h3>
    <p>
      These Terms and Conditions are an agreement between you and Hnry that
      governs your access to and use of your Debit Card through an eligible
      Digital Wallet Service. The term Digital Wallet Service includes the
      relevant branded Digital Wallet Service&rsquo;s payment functionality
      provided by Apple and Google, the Card provisioning functionality, and
      display of transaction history.
    </p>

    <h3 className="hnry-heading hnry-heading--h3">About the VISA Debit Card</h3>
    <ol>
      <li>
        Cardholder can purchase goods and services with an Eligible Device at:
        <ol type="a">
          <li>
            Near field communication (&ldquo;NFC&rdquo;) enabled merchants; and
          </li>
          <li>
            An online merchant (whether in-app or through a website), who
            accepts relevant Digital Wallet Services as a form of payment.
            Digital Wallet Services allow you to use your Device to access and
            use your Cards to make such purchases in place of presenting or
            using your physical Card.
          </li>
        </ol>
      </li>

      <li>
        Where required by relevant law, for example where you appear to be a
        &ldquo;proscribed person&rdquo;, you acknowledge that we may be required
        to block access to the Card and/or Card Fund and immediately refuse to
        process or complete any transaction or suspend or terminate our
        arrangements with you. You agree that under these circumstances we are
        under no liability to you.
      </li>
      <li>
        We reserve the right to refuse your application at our sole discretion,
        including but not limited to situations where we are unable to verify
        your identity or where your profile is inconsistent with our customer
        profile for the Card.
      </li>
      <li>
        The VISA Debit Card cannot be used at ATMs or over the counter at
        financial institutions
      </li>
      <li>
        Attempts to use the card at merchants that are not otherwise approved to
        accept the card will result in a declined transaction
      </li>
      <li>
        You agree that we may delay, block or refuse to process any transaction
        without incurring any liability if we suspect that the transaction:
        <ol type="a">
          <li>
            may breach any laws or regulations in Australia or in any other
            country;
          </li>
          <li>
            involves any person (natural, corporate or governmental) that is
            itself sanctioned or is connected, directly or indirectly, to any
            person that is sanctioned under economic and trade sanctions imposed
            by the United States Office of Foreign Assets Control, United
            Nations, the European Union or any country; or
          </li>
          <li>
            may directly or indirectly involve the proceeds of, or be applied
            for the purposes of, any unlawful conduct
          </li>
        </ol>
      </li>
    </ol>
    <h3 className="hnry-heading hnry-heading--h3">About The Digital Wallet</h3>
    <ol>
      <li>
        Use and access to the card is subject to your respective device must
        have the ability to store the card and make a purchase using a digital
        wallet
      </li>
      <li>
        By registering or using a Card to a Digital Wallet Service you agree to
        use your Card through that Digital Wall Service in accordance with these
        Terms and Conditions. If you do not agree to these Terms and Conditions,
        then you must not register your Card to, or use your Card in connection
        with, a Digital Wallet Service. You also acknowledge that your use of a
        Digital Wallet Service is subject to the terms and conditions set forth,
        and as applicable, by:
        <ol type="a">
          <li>Apple with respect to the use of Apple Pay; or</li>
          <li>Google with respect to the use of Google Pay;</li>
        </ol>
      </li>
    </ol>

    <p>none of which will change or override these Terms and Conditions.</p>
    <h3 className="hnry-heading hnry-heading--h3">About the Balance</h3>
    <ol>
      <li>
        Your are responsible for for checking your transactions history knowing
        the available balance for the card, all which will be available to you
        on your device by accessing the Hnry App
      </li>
      <li>
        You will be responsible for ensuring the availability of sufficient
        funds for all transactions
      </li>
      <li>
        The Visa Debit Card can be topped up via bank transfer or through The
        allocation feature on your Hnry App
      </li>
    </ol>
    <h3 className="hnry-heading hnry-heading--h3">
      Lost, stolen or compromised devices
    </h3>
    <ol>
      <li>
        Except to the extent required by law, we are not liable for any loss or
        damage arising out of or in any way related to the use of the card,
        including:
        <ol type="a">
          <li>
            If you permit someone else to use the Card, you will be responsible
            for any transactions initiated by that person with the card
          </li>
          <li>
            Unauthorised transactions can happen using the card if the Device is
            lost or stolen
          </li>
          <li>
            If authorisation is declined for any transaction except where the
            authorisation has been declined because of an act or omission on our
            part
          </li>
        </ol>
      </li>

      <li>
        Please contact us on +61 2 7908 7898 (9:00am to 5:00pm Monday to Friday)
        if:
        <ol type="a">
          <li>
            your Device is lost or stolen, personal identification number or
            other passcode is compromised or used or your Card has been used
            through a Digital Wallet Service without your permission, - your
            Eligible Device mobile service is suddenly disconnected without your
            permission (which may indicate your
          </li>
          <li>
            Your Device and/or your Card(s) added to Digital Wallet Service
            has/have been subject to unauthorised use or access) ; or -
          </li>
          <li>
            You suspect a security breach in relation to your Device or Digital
            Wallet Service or that an unauthorised person has used your
            passcode, Card PIN or your other credentials to access the Digital
            Wallet Service.
          </li>
        </ol>
      </li>
    </ol>

    <h3 className="hnry-heading hnry-heading--h3">Fees &amp; Charges</h3>
    <ol>
      <li>
        We currently do not impose a fee for using your VISA Debit Card through
        a Digital Wallet Service but we reserve the right to impose a fee at our
        discretion in the future.
      </li>
      <li>
        We do not charge any fees for using the card, however, to the extent
        permitted by law, some merchants may charge you for using the Card and
        such fees may be deducted from the balance of your card at the time of
        the transaction.
      </li>
    </ol>
    <h3 className="hnry-heading hnry-heading--h3">Disputed Transactions</h3>
    <ol>
      <li>
        Any refunds on Card transactions are subject to the policy of the
        specific merchant.
      </li>
      <li>
        You cannot stop a payment on any transaction after it has been
        completed. If you have a problem with a purchase made with the Card or a
        dispute with the merchant, you must deal directly with the merchant
        involved. If you can not resolve the dispute with the merchant, you can
        contact us on +61 2 7908 7898 (9:00am to 5:00pm Monday to Friday)
      </li>
    </ol>
    <h3 className="hnry-heading hnry-heading--h3">VISA Debit Card Limit</h3>
    <ol>
      <li>
        We may impose a limit on any daily and/or individual transaction
        amount(s) charged to your Card through a Digital Wallet Service.
      </li>
      <li>
        The limit will be such an amount(s) as determined by us and notified to
        you from time to time. Current limits can be found on The Hnry App or
        Hnry Website
      </li>
    </ol>
  </div>
);

export default CardsTnCsText;
