import { Address } from "@hui/_organisms/address_autocomplete/types";
import { postJson } from "./config/fetch.api";

export const waitlistInformationUK = async (waitlistInformation: waitlistInfoDto)=> 
  postJson(
    Routes.waitlist_index_path(),
    JSON.stringify(waitlistInformation),
    true,
  );


export interface waitlistInfoDto {
  jobType: {id: number, other:string}
  address: Address
  firstName: string
}