export const autoCompleteSampleData = [
  {
    id: 1,
    title: "Clothing wholesaling",
    description:
      "Wholesaling sportswear, leatherwear, menswear, womenswear, baby clothing, childrens clothing, lingerie, millinery, protective clothing, and work clothing.",
  },
  {
    id: 4,
    title: "Metalwork / Metal Product Manufacturing",
    description: null,
  },
  {
    id: 5,
    title: "Boat building",
    description:
      "This also includes painting boats, and manufacturing canoes, dinghies, jetboats, powerboats, sailboats, motorboats (inboard and outboard), and rigid inflatable boats",
  },
  { id: 6, title: "Jewellery Manufacturing", description: null },
  {
    id: 8,
    title: "Manufacturing (not elsewhere classified)",
    description: null,
  },
  {
    id: 300,
    title: "Barrister",
    description: "This includes practising as a barrister.",
  },
  {
    id: 301,
    title: "Soft drink manufacturing",
    description:
      "This also includes manufacturing non-alcoholic cider or ginger beer, tonic water, energy drinks, and bottled or canned tea drinks.",
  },
  { id: 302, title: "Fencing ", description: "Fence construction" },
  {
    id: 303,
    title: "Equipment Calibration",
    description:
      "This includes calibrating precision equipment eg meters, scales, timing equipment.",
  },
  {
    id: 304,
    title: "Environmental Consultancy Service",
    description:
      "This includes environmental consulting and auditing services. Excludes those providing laboratory services.\r\n\r\n",
  },
  {
    id: 305,
    title: "Advertising Service",
    description:
      "This includes aerial advertising and preparing advertising material. Also includes selling advertising space on a commission or fee basis.",
  },
  {
    id: 15,
    title: "Hotel/Nightclub/Bar Operation",
    description:
      "Serving customers; administrative duties; cleaning and preparation of customer areas",
  },
  {
    id: 306,
    title: "Heavy Machinery Installation",
    description:
      "Heavy machinery installation. This excludes repairing and maintaining heavy machinery.",
  },
  { id: 7, title: "Fish Trawling, Netting \u0026 Processing", description: "" },
  { id: 3, title: "Dairy Farming", description: "" },
  {
    id: 307,
    title: "Museum Operation",
    description:
      "This includes natural history, science, social history, transport, maritime, and war memorial museums.",
  },
  {
    id: 308,
    title: "Allied Health Service",
    description:
      "This includes dental hygiene, aromatherapy, reflexology, and Bowen therapy services. Also includes providing modified tai chi instruction, self-employed pharmacists who are not owner-operators, lactation consultants, and allied health services not covered by any other code.",
  },
  {
    id: 28,
    title: "Iron and Steel Forging",
    description: "Manufacturing iron or steel forgings eg forged chains.",
  },
  { id: 42, title: "Florist", description: null },
  { id: 2, title: "Pharmacist", description: null },
  { id: 10, title: "Marriage Celebrant", description: null },
  {
    id: 65,
    title: "Cosmetics \u0026 Perfume Manufacturing",
    description: null,
  },
  {
    id: 68,
    title: "Costume and Clothing Design/Manufacture",
    description: null,
  },
  {
    id: 44,
    title: "General Repair Services / Key Cutting / Locksmith",
    description: "",
  },
  {
    id: 43,
    title: "Human Resource Consultant",
    description:
      "Organisational analysis; employee and compensation planning; human capital due diligence",
  },
  {
    id: 45,
    title: "IT Hardware Consultant",
    description: "Hardware analysis; procurement; development of solutions",
  },
  { id: 47, title: "Journalist/Writer/Author", description: null },
  {
    id: 78,
    title: "Aluminium product manufacturing",
    description:
      "manufacturing architectural aluminium products eg guttering, roofing, stairs, staircases.",
  },
  {
    id: 103,
    title: "Egg/poultry farming",
    description:
      "Farming chickens, ducks, geese, game birds, or quails for eggs.",
  },
  {
    id: 82,
    title: "Aircraft maintenance and repair",
    description: "Manufacturing, maintaining, and repairing aircrafts.",
  },
  {
    id: 85,
    title: "Antique Retailing",
    description: "This includes antiques and used goods.",
  },
  { id: 93, title: "Confectionery / Cake / Biscuit Retail", description: null },
  {
    id: 113,
    title: "Fibreglass product manufacturing",
    description: "manufacturing fibreglass products",
  },
  {
    id: 100,
    title: "Dog breeding",
    description: "Breeding dogs for working, show, or domestic purposes.",
  },
  {
    id: 114,
    title: "Farrier Operation - horse shoeing",
    description: "Excluding farriers who manufacture horseshoes.",
  },
  { id: 80, title: "Animal or Pet Boarding", description: "" },
  {
    id: 81,
    title: "Air Operations - CAA rules part 115",
    description:
      "This includes pilots operating under Civil Aviation Rules Part 115. Also includes adventure aviation operations.",
  },
  { id: 142, title: "Horticultural Services / Fruit Picking", description: "" },
  { id: 119, title: "Freshwater Fishing / Shellfish Diving", description: "" },
  {
    id: 127,
    title: "Furniture upholstery",
    description:
      "manufacturing upholstered furniture and reupholstering furniture",
  },
  { id: 130, title: "Garden Supply Retailing", description: null },
  { id: 135, title: "General Retail Store", description: null },
  {
    id: 139,
    title: "3D Printing",
    description:
      "manufacturing plastic awnings, badges, lunch boxes, bathtubs, clothes pegs, cultured marble surfacing, furniture, light switches and plugs, polycarbonate sheeting, mannequins, safety goggles, shower stalls, and toilet fixtures. Also includes manufacturing plastic products by 3D printing.",
  },
  { id: 144, title: "IT Helpdesk/Support Agent", description: null },
  {
    id: 12,
    title: "Customs and shipping agent \u0026 freight-forwarding",
    description: "Customs and shipping agents and freight-forwarding services",
  },
  {
    id: 154,
    title: "Internet publishing and broadcasting",
    description:
      "online publishing of art prints, atlases, books, directories, encyclopaedias, dictionaries, greeting cards, magazines, and periodicals. Also includes internet-only radio, television, and film broadcasting. Also includes generating income through hits on internet advertising on your own website",
  },
  {
    id: 148,
    title: "IT Software/Hardware Sales",
    description:
      "Client business development, proposal writing, stakeholder management and sales",
  },
  {
    id: 118,
    title: "Forensic Investigator",
    description:
      "Studying crime scenes; gathering evidence; processing evidence; presenting findings",
  },
  { id: 128, title: "General Building Services", description: null },
  {
    id: 160,
    title: "Machinery / Equipment Repair \u0026 Maintenance",
    description: null,
  },
  {
    id: 169,
    title: "Motor vehicle assembling",
    description: "This includes assembling motor vehicles.",
  },
  {
    id: 173,
    title: "Motor vehicle rental",
    description: "Leasing, hiring, or renting campervans or motorhomes",
  },
  {
    id: 174,
    title: "Nature reserve and conservation park operation",
    description:
      "Organisations involved in preserving flora and fauna in their natural (not actively managed) environment eg nature reserves, conservation parks.",
  },
  { id: 132, title: "Glazing, Window and Blind Installation", description: "" },
  { id: 158, title: "Library \u0026 Archive Operation", description: null },
  {
    id: 214,
    title: "Short-term rental of primary residence (eg Airbnb)",
    description: null,
  },
  {
    id: 218,
    title: "Soap/Candle Manufacturing",
    description:
      "manufacturing soaps for personal, household, or commercial use",
  },
  {
    id: 224,
    title: "Spice manufacturing",
    description: "This includes manufacturing spices.",
  },
  { id: 225, title: "Stone or Monument Manufacturing", description: null },
  {
    id: 230,
    title: "Takeaway food services",
    description:
      "Food retailing where the food sold is intended for immediate consumption after purchase, including mobile (eg food trucks or vans). Also includes juice bars, and supplying food services in food halls and food courts.",
  },
  {
    id: 232,
    title: "Textile sewing and manufacturing",
    description:
      "Manufacturing canvas goods, pillows or cushions, blankets etc",
  },
  { id: 233, title: "Testing electrical equipment", description: null },
  {
    id: 236,
    title: "Textile goods retailing",
    description:
      "Includes retailing curtains, textile fabrics, manchester and soft furnishings.",
  },
  {
    id: 198,
    title: "Producer/Director/Production Assistant",
    description:
      "Producing or directing artistic or cultural work, conducting musical performances, choreography, and curating. Also includes general gofers on a film, television or theatre set.",
  },
  { id: 199, title: "Printing \u0026 Publishing Services", description: null },
  { id: 248, title: "Wood Product Manufacturing", description: null },
  {
    id: 249,
    title: "Welding \u0026 Structural Steel Services",
    description: null,
  },
  {
    id: 251,
    title: "Art Dealing",
    description:
      "This includes retailing art, ornaments, and ornamental woodware.",
  },
  {
    id: 254,
    title: "Bakery retailing",
    description:
      "This includes the production of cakes, pies, savouries, bread, rolls, biscuits, pastries, pastry dough (frozen and non-frozen), crumpets, doughnuts, puddings, and similar products in a bakery with direct (on-site) retail sale of the products to the public.",
  },
  { id: 267, title: "Cafe \u0026 Restaurant Services", description: null },
  {
    id: 270,
    title: "Cake and pastry manufacturing",
    description:
      "This ncludes the production of cakes and pastries in a bakery (factory or home) without any direct (on-site) retail sale of the products. Includes off-site retail sale (eg at markets) of cakes and pastries produced by the same business.",
  },
  {
    id: 276,
    title: "Chutney and relishes manufacturing",
    description: "This includes manufacturing chutneys or relishes.",
  },
  {
    id: 278,
    title: "Commission-based wholesaling",
    description:
      "This includes purchasing and/or selling goods to businesses or public service providers (eg schools, government) on behalf of another business on a fee or commission basis.",
  },
  { id: 241, title: "Vegetable Growing", description: null },
  {
    id: 242,
    title: "Vehicle Valet / Cleaning / Respray \u0026 Panel Service",
    description: null,
  },
  { id: 243, title: "Veterinary Services", description: null },
  {
    id: 79,
    title: "Fire, Security and Alarm system installation",
    description: "Installing or repairing alarm systems",
  },
  {
    id: 75,
    title: "Agricultural services",
    description:
      "This includes agricultural weed control, contract milking (not via a sharemilking agreement), self-employed farm labourers or assistants, agricultural machinery hire with operator.\r\n\r\nAlso includes non-veterinary health services for agricultural and equine animals (eg horse massage, horse dentistry, ultrasound scanning other than for cattle), and agricultural services not covered by any other code.",
  },
  {
    id: 121,
    title: "Forestry and logging",
    description:
      "active forest ownership or active management of forests on contract. Active refers to doing the planting, pruning or logging yourself.",
  },
  {
    id: 287,
    title: "Butcher",
    description:
      "This includes retailing fresh meat. Excludes retail butcheries providing homekill services that include the slaughter of animal",
  },
  {
    id: 292,
    title: "Pick-up and delivery service",
    description:
      "Deliveries on behalf of alternative postal services (non-NZ Post), local deliveries of newspapers in bulk, and goods pickup and delivery services not covered by any other code",
  },
  {
    id: 172,
    title: "Network Marketing / Non-Store Retailing",
    description: "",
  },
  {
    id: 9,
    title: "Holiday home management",
    description:
      "Managing holiday homes and flats on behalf of the owners (including taking bookings and arranging for general maintenance of the holiday homes and flats)",
  },
  { id: 11, title: "Fashion / Wardrobe Stylist", description: null },
  {
    id: 13,
    title: "Pilot - Small Aircraft \u0026 Helicopters",
    description:
      "Flying small aircraft, flying helicopters, teaching in a flight school",
  },
  { id: 14, title: "Personal Chef Services", description: null },
  {
    id: 16,
    title: "Private Investigator / Security Guard / Bodyguard",
    description: null,
  },
  {
    id: 40,
    title: "Courier / Food Delivery Service",
    description:
      "Driving or riding to various locations; delivery of packages or other objects; office administration - Uber eats, delivereasy",
  },
  {
    id: 87,
    title: "Arborists and Tree Surgeons",
    description: "This includes arborists and tree surgeons.",
  },
  {
    id: 209,
    title: "Scientific Research (Except University)",
    description:
      "This includes life science research institutions (eg marine biology, biotechnology, zoology, botany) that are not part of a university.",
  },
  { id: 228, title: "Stud Farm Operator", description: "" },
  {
    id: 17,
    title: "Theatre / Orchestra / Circus Operation",
    description: null,
  },
  { id: 18, title: "Event Management Services", description: null },
  { id: 19, title: "Arts, Dance and Drama Education", description: null },
  {
    id: 20,
    title: "Administrative Professional / Office Management",
    description:
      "Office administration; writing business correspondence; executive administration",
  },
  {
    id: 21,
    title: "Marketing/Brand Consulting",
    description:
      "Client consultations; organising marketing campaigns; campaign management; stakeholder management",
  },
  { id: 22, title: "Broadcasting Services - Radio", description: null },
  {
    id: 23,
    title: "Image Consultant",
    description:
      "Client consultations; client goal-setting;  image management activities",
  },
  {
    id: 24,
    title: "Film Production, Videography \u0026 Visual Effects",
    description:
      "Post-production services; Sound editing or dubbing; Designing and creating visual effects",
  },
  { id: 25, title: "Broadcasting Services - TV", description: null },
  {
    id: 26,
    title: "Market research service",
    description:
      "This includes statistical bureau operations (including government), statistical consulting services, and market research by phone and other methods.",
  },
  { id: 27, title: "Film \u0026 TV Crew", description: null },
  {
    id: 29,
    title: "Bird breeding",
    description:
      "Except poultry or game birds. This also includes farming emus and ostriches.",
  },
  {
    id: 30,
    title: "Paramedic",
    description:
      "This includes self-employed paramedics, and non-medical nursing care.",
  },
  {
    id: 31,
    title: "Bricklaying services",
    description: "Laying or repairing clay, refractory, and concrete bricks.",
  },
  {
    id: 32,
    title: "Business Analyst",
    description: "Process Mapping; Running Workshops; Stakeholder Management;",
  },
  {
    id: 33,
    title: "Business And Management Consultant",
    description:
      "Business strategy; business analysis and research; technical analysis; stakeholder management",
  },
  { id: 34, title: "Chiropractic / Osteopathic Services", description: null },
  {
    id: 35,
    title: "CX/UX Consultant",
    description:
      "CX/UX research; user testing; customer workshops; documenting customer experiences",
  },
  { id: 36, title: "Civil Engineering Consultant", description: null },
  {
    id: 37,
    title: "Communications \u0026 Public Relations",
    description: null,
  },
  {
    id: 38,
    title: "Energy Efficiency/Rating Consultant",
    description:
      "Organisational energy analysis; creating reports; making recommendations",
  },
  {
    id: 39,
    title: "Copywriter",
    description:
      "Creating new content or copy; reviewing copy; publishing copy; stakeholder management",
  },
  {
    id: 41,
    title: "Electrical or Mechanical Engineering Consultant",
    description:
      "Designing circuits and systems; creating prototypes; testing products",
  },
  {
    id: 46,
    title: "IT Software Consultant",
    description: "Software analysis; procurement; development of solutions",
  },
  {
    id: 48,
    title: "Life Coach",
    description:
      "Performing consultations; administering coaching sessions; practice administration",
  },
  {
    id: 49,
    title: "Mediation \u0026 Adjudication for Businesses",
    description: null,
  },
  { id: 50, title: "Midwifery Services", description: null },
  {
    id: 51,
    title: "Personal Trainer/Fitness Instructor",
    description:
      "Providing various exercises and routines; watching clients do exercises; demonstrating correct exercise techniques",
  },
  {
    id: 52,
    title: "Programme Manager",
    description:
      "Governance establishment and monitoring; risk management; budgeting; resource management",
  },
  {
    id: 53,
    title: "Occupational Health and Safety Consultant",
    description:
      "Organisational health \u0026 safety analysis; creating reports; making recommendations; delivering health \u0026 safety programmes",
  },
  {
    id: 54,
    title: "Nursery Worker/Nanny",
    description:
      "Educating children; preparing meals; taking children to activities",
  },
  {
    id: 55,
    title: "Project Manager",
    description:
      "Governance establishment and monitoring; risk management; budgeting; resource management",
  },
  {
    id: 56,
    title: "Quantity Surveyor",
    description:
      "Preparing tender and contract documentation; cost analysis; risk analysis;",
  },
  {
    id: 57,
    title: "Social Media/Content Manager",
    description:
      "Reviewing website layouts; Creating new website content; publishing designs and specifications; stakeholder management",
  },
  {
    id: 58,
    title: "Sports Coaching (non-professional sport)",
    description:
      "Coaching teams; planning training sessions; Researching drills",
  },
  { id: 59, title: "Support Desk / Call Centre Agent", description: null },
  {
    id: 60,
    title: "Taxi/Uber Driver / Private Hire",
    description:
      "Picking up passengers; driving specific routes; delivering passengers to their destination",
  },
  {
    id: 61,
    title: "Translation/Interpretation Services",
    description: "Language interpretation; training; education",
  },
  {
    id: 62,
    title: "User Interface Designer",
    description:
      "User interface design; user interface development; user testing",
  },
  {
    id: 63,
    title: "Website Designer",
    description: "Website design; website development; user testing",
  },
  {
    id: 64,
    title: "Construction worker",
    description:
      "Constructing part or all of a structure; fabricating materials; working on building sites",
  },
  {
    id: 66,
    title: "Construction/Site Manager",
    description:
      "Monitoring construction progress; managing risks; undertaking safety checks",
  },
  {
    id: 122,
    title: "Forest conservation service",
    description: "Forest conservation services",
  },
  {
    id: 67,
    title: "Culling of Wild Animals",
    description:
      "This includes shooting, trapping, and poisoning wild animals (including aerial shooting or trapping).",
  },
  {
    id: 69,
    title: "Counsellor / Psychologist",
    description:
      "Performing consultations; administering therapy sessions; practice administration",
  },
  {
    id: 70,
    title: "Credit Reporting \u0026 Debt Collection Services",
    description: null,
  },
  {
    id: 71,
    title: "Youth Coordinator",
    description:
      "Community education, and education not covered by any other code eg language schools, instruction through Universities of the Third Age and Schools for Seniors, public speaking training, survival skill training (including first aid training), community education programmes, parental education programmes, craft classes, children activity (non-sport) classes, relaxation classes",
  },
  {
    id: 72,
    title: "Acupuncturist",
    description:
      "Performing consultations; administering acupuncture sessions; practice administration",
  },
  {
    id: 73,
    title: "Aerial Photography Service",
    description: "This excludes freefall photography services.",
  },
  {
    id: 74,
    title: "Actor",
    description:
      "This also includes working as extras or stunt performers on films, television, and advertising commercials.",
  },
  {
    id: 76,
    title: "Adult/Community Education",
    description:
      "Adult education, community education, and education not covered by any other code eg language schools, instruction through Universities of the Third Age and Schools for Seniors, public speaking training, survival skill training (including first aid training), community education programmes, parental education programmes, craft classes, children activity (non-sport) classes, relaxation classes.",
  },
  { id: 77, title: "Air Conditioning Installer", description: null },
  {
    id: 83,
    title: "Animation/Motion Design",
    description:
      "Digital visual effects services eg compositing, matte painting, animation, lighting effects, texture and character rigging, computer graphics.",
  },
  { id: 84, title: "Archaeologist", description: null },
  { id: 86, title: "Architecture \u0026 Planning Services", description: null },
  {
    id: 88,
    title: "Computer maintenance service",
    description:
      "This includes repairing and maintaining computers, printers, monitors, and servers. Also includes recovering data from computers, mobile phones, software, and fixed or mobile storage devices.",
  },
  {
    id: 89,
    title: "Computer Systems Design And Related Services",
    description: null,
  },
  {
    id: 90,
    title: "Computer consultancy service",
    description:
      "This includes providing expertise and advice related to information technology (IT). This may include advising on solutions to meet the needs of a particular client, or planning computer systems that integrate computer hardware, software, and communication technologies.",
  },
  {
    id: 91,
    title: "Concreting services",
    description:
      "Constructing concrete or asphalt concrete driveways, and concrete work on construction projects.",
  },
  {
    id: 92,
    title: "Compliance Analyst",
    description:
      "Analysing compliance; compliance monitoring; developing compliance programmes",
  },
  {
    id: 94,
    title: "Data entry",
    description:
      "providing electronic or automated data entry services, data capture imaging services, and optical scanning services",
  },
  { id: 95, title: "Dental Services", description: null },
  {
    id: 96,
    title: "Data Analyst",
    description: "Data analysis; data mining and cleansing; data reporting",
  },
  { id: 97, title: "Dog walking", description: "Dog walking services" },
  { id: 98, title: "Driving Instructor", description: null },
  {
    id: 99,
    title: "Domestic or Industrial Cleaning Services",
    description:
      "Cleaning of properties or locations both Commercial and Residential",
  },
  {
    id: 101,
    title: "Dog grooming and training",
    description:
      "Various grooming duties necessary to improve pets’ hygiene and enhance their physical appearance. Bath, brush, and dry pets, clean their teeth and ears, as well as trim their nails and hair/fur. This includes dog training (obedience) for domestic dogs.",
  },
  {
    id: 102,
    title: "Doctor/GP",
    description:
      "Performing consultations; practice administration; specialist research",
  },
  {
    id: 104,
    title: "Earth Moving Services",
    description: "This includes bulldozing construction sites.",
  },
  {
    id: 105,
    title: "Editing, Transcription and Document Preparation",
    description: null,
  },
  {
    id: 106,
    title: "Educational support services",
    description:
      "Teacher aids and educational facilitators not otherwise classified.",
  },
  { id: 107, title: "Employment Placement and Recruitment", description: null },
  {
    id: 108,
    title: "Electrical equipment manufacturing",
    description:
      "Manufacturing distribution boards, fuses or cutouts, magnets, soldering irons, electrical transmission equipment, and electrical equipment",
  },
  {
    id: 109,
    title: "Electric Scooter Recharger",
    description: "Relocation and recharge of electric scooters or bikes",
  },
  { id: 110, title: "Electrician", description: "Electrician" },
  {
    id: 111,
    title: "Engineering drafting service",
    description: "This includes engineering draughting services.",
  },
  { id: 237, title: "Technology Research Services", description: null },
  {
    id: 112,
    title: "Entertainer/Variety Performer",
    description:
      "Various performing arts and entertainment activities, either in public, in a studio or in a venue, DJ",
  },
  {
    id: 115,
    title: "Financial/Management Accountant",
    description:
      "Preparing reports, budgets, commentaries and financial statements; undertaking financial administration and internal audits",
  },
  {
    id: 116,
    title: "Financial Services",
    description:
      "This only includes financial consultants or advisors, financial planning and support services (eg budgeting), and financial services not covered by any other code.",
  },
  { id: 117, title: "Fire fighting service", description: null },
  { id: 120, title: "Fortune Telling/Clairvoyance Services", description: "" },
  {
    id: 123,
    title: "Function equipment renting \u0026 hiring",
    description:
      "renting, leasing, or hiring function equipment (including catering equipment and party requirements eg bouncy castles)",
  },
  {
    id: 124,
    title: "Fundraising (commission based)",
    description:
      "fundraising or collecting donations for others on a commission or fee basis, street collectors, door-to-door collectors, selling items (eg badges, chocolates) for fundraising groups, crowd funding, and crowd equity fundraising",
  },
  { id: 125, title: "Funeral \u0026 Cemetery Services", description: null },
  {
    id: 126,
    title: "Furniture Removals",
    description:
      "This includes removing and transporting furniture and household items by road.",
  },
  { id: 129, title: "General Labour", description: null },
  { id: 131, title: "Gardening Services", description: null },
  {
    id: 133,
    title: "Yoga Teacher",
    description: "Teaching classes, working with clients, planning classes",
  },
  {
    id: 134,
    title: "Grocery home delivery service",
    description:
      "This also includes food and pizza delivery services eg Uber Eats",
  },
  {
    id: 136,
    title: "Graphic Designer",
    description:
      "Client consultations; creating images and design assets; presenting designs back to clients and stakeholders",
  },
  {
    id: 137,
    title: "Hairdresser / Make-up Artist / Barber",
    description: null,
  },
  {
    id: 138,
    title: "Health \u0026 Safety Consultant",
    description:
      "Organisational health \u0026 safety analysis; creating reports; making recommendations; delivering health \u0026 safety programmes",
  },
  {
    id: 140,
    title: "Homecare Service",
    description:
      "This includes those (including self-employed) providing attendant care and physical caregiving services to people requiring help to complete activities of daily life due to physical or medical limitations eg helping to move around the house, showering, lifting in and out of bed etc.",
  },
  { id: 141, title: "Horse Riding / Training", description: null },
  {
    id: 143,
    title: "IT Network Architect",
    description: "Network analysis; procurement; development of solutions",
  },
  {
    id: 145,
    title: "IT Software Development",
    description: "Software analysis; procurement; development of solutions",
  },
  {
    id: 146,
    title: "IT Software Programmer",
    description: "Software analysis; procurement; development of solutions",
  },
  {
    id: 147,
    title: "IT Software Tester",
    description:
      "Software analysis; creation of test scripts; testing of software solutions",
  },
  {
    id: 149,
    title: "IT Training",
    description:
      "Training needs analysis; development of training programmes; delivery of training programmes and events",
  },
  {
    id: 150,
    title: "Insurance Broker / Actuarial Services",
    description: null,
  },
  {
    id: 151,
    title: "In-store Promotions and Merchandising Services",
    description: null,
  },
  { id: 152, title: "Investment Property Rental Income", description: "" },
  { id: 153, title: "Interior Designer", description: null },
  { id: 155, title: "Lawyer", description: null },
  {
    id: 156,
    title: "Legal Executive",
    description:
      "Filing legal documents; researching case history; providing legal advice; client engagement",
  },
  {
    id: 157,
    title: "Landscape Construction",
    description:
      "This includes constructing new landscapes, ponds, gravel driveways, and retaining walls and decks not requiring building consent.",
  },
  {
    id: 159,
    title: "Legal Secretary",
    description:
      "Report preparation; minute taking; legal documentation; financial budgets and reconciliation",
  },
  {
    id: 161,
    title: "Management Training",
    description:
      "Creation of training courses; delivery of training courses; stakeholder management",
  },
  {
    id: 162,
    title: "Management Consultant",
    description:
      "Business strategy; business analysis and research; technical analysis; stakeholder management",
  },
  {
    id: 163,
    title: "Management Services \u0026 Related Consulting Services",
    description: null,
  },
  { id: 164, title: "Marine Engineer", description: null },
  {
    id: 165,
    title: "Massage Therapist",
    description:
      "Performing consultations; administering massage sessions; practice administration",
  },
  {
    id: 166,
    title: "Meteorological \u0026 Biosecurity Services",
    description: null,
  },
  { id: 167, title: "Medical Imaging Services", description: null },
  { id: 168, title: "Migration Consulting and Services", description: null },
  { id: 170, title: "Mortgage Broker / Finance Consultant", description: null },
  { id: 171, title: "Modelling", description: null },
  { id: 175, title: "Nurse Practitioner", description: null },
  {
    id: 176,
    title: "Musician/Performer/Composer",
    description:
      "Writing music; performing music either in studios or in a venue; voice-over work",
  },
  {
    id: 177,
    title: "Nurse",
    description:
      "Nursing care (predominantly medical) provided by self-employed contractors or through providers eg nursing bureaus. Also includes self-employed occupational safety and health nurses.",
  },
  {
    id: 178,
    title: "Nursing Care (in patient's home)",
    description:
      "This includes nursing care (predominantly medical) provided in a patient's home by self-employed contractors or through providers eg nursing bureaus.",
  },
  { id: 179, title: "Nutritionist / Dietitian", description: null },
  {
    id: 180,
    title: "Occupational Therapist",
    description: "occupational therapist",
  },
  {
    id: 181,
    title: "Oil/Gas/Mining Engineer",
    description:
      "Recording information on extraction activities; ensuring regulatory compliance; interpreting results",
  },
  {
    id: 182,
    title: "Ops/Maintenance Manager",
    description:
      "Overseeing installation and repair of equipment; administration; budgeting and cost negotiation",
  },
  { id: 183, title: "Painter / Decorator", description: null },
  {
    id: 184,
    title: "Outdoor Education",
    description: "Outdoor pursuits with an educational theme",
  },
  {
    id: 185,
    title: "Paralegal",
    description:
      "Drafting settlements; preparing court filings; performing legal research; other auxiliary legal services",
  },
  {
    id: 186,
    title: "Payroll Analyst",
    description:
      "Preparing accounting papers, reconciling accounts; managing payroll processes",
  },
  {
    id: 187,
    title: "Pest control services",
    description:
      "Control and extermination (including fumigation) of insects and rodents in commercial, industrial, and domestic situations",
  },
  { id: 188, title: "Physiotherapist", description: null },
  {
    id: 189,
    title: "Photographer",
    description:
      "Client consultations; taking photos of various subjects; digital transformation of photos",
  },
  {
    id: 190,
    title: "Personal stylist service",
    description:
      "This includes providing advice and tips on clothing styles, new fashion trends, and personal image.",
  },
  {
    id: 191,
    title: "Pilot - Medium \u0026 Large Aircraft",
    description: "Flying medium \u0026 large aircraft",
  },
  { id: 192, title: "Plastering \u0026 Ceiling Services", description: null },
  {
    id: 193,
    title: "Plumber",
    description:
      "Visiting clients; performing plumbing activities; office administration",
  },
  { id: 194, title: "Podiatry / Chiropodist Services", description: null },
  { id: 195, title: "Primary Education", description: null },
  {
    id: 196,
    title: "Policy Adviser",
    description:
      "Policy analysis and research; data analysis; stakeholder relationship management",
  },
  {
    id: 197,
    title: "Product Design (inc. Furniture, \u0026 Equipment)",
    description: null,
  },
  { id: 200, title: "Professional Sport / Athlete", description: null },
  {
    id: 201,
    title: "Professional, scientific and technical services",
    description:
      "This includes scientists (eg biologists, entomologists (except those engaged in research or testing services)). Also includes locating underground cables and utilities, non-financial asset broking, and professional, scientific, and technical services not covered by any other code.",
  },
  {
    id: 202,
    title: "Public Affairs Consultant",
    description:
      "This includes public affairs consulting and political lobbying services.",
  },
  {
    id: 203,
    title: "Product Owner",
    description:
      "Strategic planning; delivery management; resource allocation; user experience analysis",
  },
  { id: 204, title: "Sales Representative (Commission)", description: "" },
  { id: 205, title: "Real Estate \u0026 Property Management", description: "" },
  { id: 206, title: "Roofing", description: null },
  {
    id: 207,
    title: "Public Speaker / MC",
    description:
      "Public speaking, master of ceremonies (MC), celebrity speaker",
  },
  {
    id: 208,
    title: "Repairs and Maintenance",
    description:
      "This includes repairing, restoring, or maintaining antiques, bicycles, musical instruments (including tuning), and wheelchairs.",
  },
  {
    id: 210,
    title: "Set Design / Theatre Lighting Services",
    description: null,
  },
  { id: 211, title: "Shearing Services", description: null },
  {
    id: 212,
    title: "Scientific testing and analysis services",
    description:
      "Analysing samples and running toxicology tests; interpreting and presenting results",
  },
  {
    id: 213,
    title: "Sign language interpreter",
    description:
      "Interpreting oral or sign communications into another language",
  },
  { id: 215, title: "Solution Architect", description: "" },
  {
    id: 216,
    title: "Signwriting",
    description: "Signwriter, signage installation ",
  },
  {
    id: 217,
    title: "Social Worker / Support Worker / Disability Worker",
    description:
      "Identifying people who need help; developing plans to improve their wellbeing; researching community services",
  },
  { id: 219, title: "Sound Recording / Engineering", description: null },
  { id: 220, title: "Specialised Design Services", description: "" },
  { id: 221, title: "Specialist medical services", description: null },
  { id: 222, title: "Speech Therapy / Audiology Services", description: null },
  {
    id: 223,
    title: "Sports Goods Manufacturing",
    description:
      "Manufacturing equipment for fitness (eg gym equipment), archery, cricket or lawn bowls",
  },
  {
    id: 226,
    title: "Stockbroking",
    description:
      "This includes sharebroking and investing clients' funds in stocks.",
  },
  {
    id: 227,
    title: "Sports and physical education instruction",
    description: null,
  },
  { id: 229, title: "Taxi And Road Transport", description: null },
  {
    id: 231,
    title: "Tattooing / Piercing / Personal styling",
    description: null,
  },
  {
    id: 234,
    title: "Telecommunication service",
    description:
      "Communication service (wired) – including telephone, telex servicing",
  },
  {
    id: 235,
    title: "Teacher / Tutor",
    description: "Planning classes; teaching classes; marking performance",
  },
  {
    id: 238,
    title: "Truck Driver",
    description:
      "Transporting heavier or larger freight items on a regular basis",
  },
  {
    id: 239,
    title: "Travel Agency, Touring and Guide Services",
    description: null,
  },
  {
    id: 240,
    title: "Transport support services",
    description:
      "This only includes road passenger terminal operations, road vehicle driving services (except owner-operators), road pilotage services, bus drivers and truck drivers who are not owner-drivers, and relocation of rental cars and other vehicles.\n\nAlso includes relocation and recharge of electric scooters or bikes.",
  },
  { id: 244, title: "Warehouse Services", description: null },
  { id: 245, title: "Web Hosting incl. Streaming Services", description: null },
  {
    id: 246,
    title: "Workplace Training",
    description:
      "This includes coaching and mentoring services provided in the workplace, work-related computer education or training, and workplace safety training. Also includes co-ordinators providing educational and organisational services to apprentices.",
  },
  { id: 247, title: "Wellness \u0026 Holistic Therapy", description: null },
  {
    id: 250,
    title: "Hazardous material removal",
    description: "Removing, treating or disposing of hazardous waste",
  },
  { id: 252, title: "Automotive Repair \u0026 Maintenance", description: null },
  { id: 253, title: "Artist/Sculptor", description: null },
  {
    id: 255,
    title: "Beauty Therapist",
    description:
      "Application and sales of make-up and treatments; administrative duties; record keeping and data updating",
  },
  {
    id: 256,
    title: "Bin hiring \u0026 Waste collection service",
    description: null,
  },
  { id: 257, title: "Boat or Deck Crew (non-Fishing)", description: "" },
  { id: 258, title: "Beekeeping", description: "" },
  {
    id: 259,
    title: "Brewer",
    description: "This includes manufacturing beer and alcoholic ginger beer.",
  },
  {
    id: 260,
    title: "Book Keeper",
    description:
      "Financial transaction recording and reporting; ledger management",
  },
  { id: 261, title: "Builder / House Construction", description: null },
  {
    id: 262,
    title: "Brothel/Escort/Adult Entertainment Services",
    description: "",
  },
  {
    id: 263,
    title: "Building Consultant",
    description:
      "Self-employed engineering or structural property assessors and building inspectors.",
  },
  {
    id: 264,
    title: "Business Process Consultant",
    description:
      "Business process analysis; process mapping; stakeholder workshops",
  },
  {
    id: 265,
    title: "Business Mentor/Coach",
    description:
      "Business advisory services; coaching business leaders; industry analysis",
  },
  {
    id: 266,
    title: "CAD/CAE Engineer",
    description:
      "Surface modelling; 3D display creation; preparing cost estimates",
  },
  { id: 268, title: "Carpenter / Joiner", description: null },
  {
    id: 269,
    title: "Car Retailing \u0026 Associated Servicing",
    description: null,
  },
  { id: 271, title: "Carpeting \u0026 Tiling Services", description: null },
  { id: 272, title: "Catering / Kitchen Services", description: null },
  {
    id: 273,
    title: "Change Management Consultant",
    description:
      "Change impact analysis; organisational analysis; delivery of personal development and training initiatives",
  },
  { id: 274, title: "Child Care Services", description: null },
  {
    id: 275,
    title: "Child Care Service - In home",
    description:
      "Non-Early Childhood Education (ECE) registered childminding in the home, au pair services, nanny services, and babysitting services in the home.",
  },
  {
    id: 277,
    title: "Commodity Broker",
    description: "Acting as a broker for commodity sales or purchases",
  },
  {
    id: 279,
    title: "Commercial Diving (Salvage \u0026 Inspection)",
    description: null,
  },
  {
    id: 280,
    title: "Civil Engineer",
    description:
      "Professional consultation; evaluating site suitability; planning and design of structures; report preparation",
  },
  { id: 281, title: "Other", description: null },
  {
    id: 282,
    title: "Race course or track operation",
    description: "This includes race courses or tracks for dog racing.",
  },
  { id: 283, title: "Flower Growing ", description: "Flower growing" },
  {
    id: 284,
    title: "Demolition of buildings or other structures",
    description:
      "This includes demolishing buildings and other structures eg bridges, chimneys. Excludes interior demolitions (including removal of walls, windows, carpets etc) for later construction by others.",
  },
  {
    id: 285,
    title: "Leather Goods Manufacturing ",
    description:
      "This includes manufacturing leather packing, leather belting, sheepskin seat covers, leather toys, leather wallets (including metal mesh wallets), and leather and leather substitute goods not covered by any other code.",
  },
  { id: 286, title: "Health care services", description: "" },
  {
    id: 288,
    title: "Religious Services ",
    description:
      "This also includes self-employed ministers and the operation of mosques, synagogues, and other religious temple or shrines.\r\n",
  },
  {
    id: 289,
    title: "Surveying and Mapping Services",
    description: "This includes map preparation services.",
  },
  {
    id: 290,
    title: "Personal Services",
    description:
      "This includes providing services eg preparing meals, cleaning, washing etc not in conjunction with physical caregiving.",
  },
  {
    id: 291,
    title: "Cyclist ",
    description: "This includes professional cycling.",
  },
  {
    id: 293,
    title: "Cryptocurrency Mining",
    description:
      "This includes cryptocurrency mining or blockchain services eg Bitcoin.",
  },
  {
    id: 294,
    title: "Cryptocurrency Investing",
    description:
      "This includes investing your own funds in cryptocurrency eg Bitcoin.",
  },
  {
    id: 295,
    title: "Plant nursery operation ",
    description:
      "Growing bedding plants, perennials, seedlings, Christmas tree and the propagation of bulbs",
  },
  {
    id: 296,
    title: "Golf Caddy",
    description: "Sporting club or association - golf ",
  },
  {
    id: 297,
    title: "Picture Frame Manufacturing ",
    description:
      "This includes manufacturing frames and frame mouldings for picture and mirror frames.",
  },
  {
    id: 298,
    title: "Food Safety Regulator ",
    description:
      "Regulating food and agricultural standards. This is only for organisations mainly engaged in enforcing food and agricultural regulations, licensing, and inspection activities.",
  },
  {
    id: 299,
    title: "Aviation Consultant/ Airport Operations ",
    description: "This includes airport terminal operations.",
  },
];
