import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ModuleItem, { ModuleItemProps } from "./ModuleItem";

const SortableItem = ({ id, dashedBorder, moduleData }: ModuleItemProps) => {
  const {
      isDragging,
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <ModuleItem
      ref={setNodeRef}
      moduleData={moduleData}
      style={style}
      withOpacity={isDragging}
      id={id}
      dashedBorder={dashedBorder}
      {...attributes}
      {...listeners}
    />
  );
};

export default SortableItem;
