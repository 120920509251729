import React, { useState } from "react";
import InputPrice from "../../_atoms/input/InputPrice";
import I18n from "../../../utilities/translations";

interface iWithdrawFunds {
  availableBalance: number;
  withdrawAmount?: string;
  setWithdrawAmount: (string) => void;
  setDisabledSubmit: (boolean) => void;
  canWithdraw: boolean;
  jurisdiction: string;
}

const WithdrawFunds = ({
  availableBalance,
  withdrawAmount,
  setWithdrawAmount,
  setDisabledSubmit,
  canWithdraw,
}: iWithdrawFunds) => {
  const [invalidText, setInvalidText] = useState("");

  const handleChange = (value) => {
    const newValue = Number(value);

    if (newValue > Number(availableBalance) || newValue <= 0) {
      setInvalidText(I18n.t("home.hnry_card.withdraw_funds.invalid_message"));
      setDisabledSubmit(true);
    } else {
      setInvalidText("");
      setDisabledSubmit(false);
    }
    setWithdrawAmount(value);
  };

  const { currencySymbol } = window.Hnry.User.jurisdiction;

  return (
    <>
      <p className="tw-text-gray-800">
        {I18n.t("home.hnry_card.withdraw_funds.withdraw_info")}
      </p>
      <InputPrice
        currencySign={currencySymbol}
        value={Number(availableBalance || 0).toFixed(2)}
        id="card_balance"
        name="card[balance]"
        label="Card balance"
        disabled
      />
      <InputPrice
        currencySign={currencySymbol}
        id="card_withdraw_amount"
        name="card[withdraw_amount]"
        label="Enter withdrawal amount"
        onChange={handleChange}
        onBlur={handleChange}
        value={withdrawAmount}
        invalid={invalidText}
        disabled={!canWithdraw}
      />
      {!canWithdraw ? (
        <p className={"alert alert-warning"}>
          {I18n.t("home.hnry_card.withdraw_funds.cannot_withdraw")}
        </p>
      ) : (
        ""
      )}
    </>
  );
};

export default WithdrawFunds;
