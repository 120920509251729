import React, { ReactNode, useState } from "react";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import Icon from "../../_atoms/icons/icon/Icon";
import "./styles.scss"

interface iDropdown {
  ariaLabel?: string;
  buttonText?: string;
  children?: ReactNode;
  id: string;
}

const Dropdown = ({
  ariaLabel,
  buttonText = "Select...",
  children,
  id,
}: iDropdown) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <RadixDropdownMenu.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <RadixDropdownMenu.Trigger
        className="hui-dropdown__button hui-dropdown__wrapper"
        aria-label={ariaLabel}
      >
        <div className="tw-truncate">{buttonText}</div>
        <span className="hui-dropdown__icon">
          <Icon type="ChevronDownIcon" classes="!tw-text-gray-500 tw-stroke-2" size="sm" />
        </span>
      </RadixDropdownMenu.Trigger>
      <RadixDropdownMenu.Portal container={document.getElementById(id)}>
        <RadixDropdownMenu.Content
          className="hui-dropdown__contentbox"
          side="bottom"
          sideOffset={12}
          align="end"
        >
          {children}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
}

export default Dropdown;
