import React, { useEffect, useState, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import clone from "lodash/clone";
import isEqual from "lodash/isEqual";
import { AnimatePresence, motion } from "framer-motion";
import NumberInput from "cleave.js/react"; // Just renaming the default export from Cleave, we should have our own number input ideally
import { round2Dp } from "../../utils/base_helper";
import { useInvoiceQuoteContext } from "../InvoiceQuoteContext";
import Icon from "../../icon/Icon";
import Typedown from "../../inputs/typedown/typedown";
import Toggle from "../../inputs/toggle/Toggle";
import Ellipses from "../../ellipses/ellipses";
import CurrencyInput from "../../inputs/currency/CurrencyInput";
import Button from "../../_atoms/button/Button";
import I18n from "../../../utilities/translations";
import isMobile from "../../../es_utilities/isMobile";

// A component to encapsulate the logic and UI for the LineItems (InvoiceItems and QuoteItems) that
// exist on an Invoice or Quote. Line Items can be added, removed, edited, and reordered on an
// Invoice or Quote and the logic for all of these actions belongs in this component.
// Like the rest of the Invoice and Quote components, it uses the InvoiceQuoteContext
// as its source of truth
const LineItems = ({ lineItemsAttributesName, model }) => {
  // The defaultService is what gets added to the Form as a new line when the "Add line" button is pressed
  const [defaultService, setDefaultService] = useState();
  // Keep track of which existing LineItems have been removed, so they can be destroyed on the server
  const [removedLineItems, setRemovedLineItems] = useState([]);
  const [hideGstOnMobile, setHideGstOnMobile] = useState(false);

  // Grab the relevant values out of the context
  const {
    allowSalesTaxExemptItems,
    includesSalesTax,
    invoiceObject,
    lineItems,
    otherProps,
    salesTaxName,
    salesTaxRate,
    setLineItems,
    status,
  } = useInvoiceQuoteContext();

  useEffect(() => {
    if (window.innerWidth < window.breakpoints.mobileMax) {
      setHideGstOnMobile(true);
    }
  }, []);

  // Pretty much an onMount effect
  // This handles:
  // - Making sure lineItems are sorted by `order`
  // - Making sure we have a valid `defaultService` so that new lines can be added
  useEffect(() => {
    // If there are any lineItems, make sure they're sorted properly
    if (lineItems.length > 0) {
      let updated = lineItems.slice();
      updated = updated.sort((a, b) => a.order > b.order);

      // recalculate sales_tax if user registered as sales tax after the invoice is created
      if (status === "DRAFT") {
        updated = updated.map((lineItem) => {
          const { sales_tax, total, includes_sales_tax } = lineItem;

          let recalculateSalesTax = 0;
          if (includesSalesTax && sales_tax == 0 && includes_sales_tax) {
            recalculateSalesTax = total * salesTaxRate;
          } else if (!includesSalesTax && sales_tax != 0) {
            recalculateSalesTax = 0;
          } else {
            recalculateSalesTax = sales_tax;
          }

          return { ...lineItem, sales_tax: recalculateSalesTax };
        });
      }

      setLineItems(updated);
    }
    // `otherProps` is used as a flag here to make sure that this runs only when there
    // are values in the context. Usually on the first render, the context will not be populated
    // but on the next render it will be so this part can run at that point.
    // This part formats and sets a `defaultService` which new lineItems use as a baseline when added
    if (otherProps) {
      const firstActiveService = otherProps.services[0];
      const formattedService = {
        archived: false,
        updated_name: "",
        service_id: firstActiveService[1],
        category: firstActiveService[2]["data-category"],
        service_price: "",
        quantity: "",
        modelId: invoiceObject.id,
        model,
      };
      setDefaultService(formattedService);
    }
  }, [otherProps]);

  // Handles adding a new item to the list of LineItems
  const addNewItem = () => {
    // Determines what the `order` number for the new item should be
    let currentHighestOrderNumber = 0;

    if (lineItems.length) {
      currentHighestOrderNumber = Math.max(
        Math.max(...lineItems.map(({ order }) => order)),
        0,
      ); // Double Math.max to make sure 0 is the lowest possible order
    }
    const nextOrderNumber = currentHighestOrderNumber + 1;
    // Using the `defaultService`, create a baseline for the new lineItem,
    // then set all values on it as required
    const newItem = clone(defaultService);
    newItem.order = nextOrderNumber;
    newItem.quantity = 1;
    newItem.total =
      parseFloat(newItem.service_price) * parseFloat(newItem.quantity) || 0;
    newItem.sortID = crypto.randomUUID();
    (newItem.sales_tax = includesSalesTax ? newItem.total * salesTaxRate : 0),
      (newItem.includes_sales_tax = includesSalesTax);

    setLineItems([...lineItems, newItem]);
  };

  // Pretty much an onMount effect
  // We want an Invoice/Quote to have one blank line by default so users can start working on it quickly
  // To do this, we wait untill the `defaultService` is set, and if there are no lineItems,
  // we add a blank one to start off the Invoice/Quote.
  // We wait for defaultService to be set because at the time that it is set, we know that all the lineItems
  // have been loaded. If they've all been loaded and the length is still 0, we know to add a starter line
  useEffect(() => {
    if (!lineItems.length && defaultService) {
      addNewItem();
    }
  }, [defaultService]);

  // Removes a LineItem from the list of lineItems.
  // Essentially we just filter out the provided item by removing the item that has the matching `order`.
  // `removedLineItems` gets updated too so that we can keep track of any persisted items that need to be
  // destroyed on the server
  const removeItem = (item) => {
    // Filters out null values, i.e.: items that had not been passed in as props and need to be removed
    const filteredItems = lineItems.filter((itm) => itm.sortID !== item.sortID);

    setLineItems(filteredItems);
    setRemovedLineItems([...removedLineItems, item]);
  };

  // Handles reordering the list of lineItems once an item has been dragged and dropped
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return;
    const newItems = reorder(
      lineItems,
      result.source.index,
      result.destination.index,
    );

    setLineItems(newItems);
  };

  // a little function to help us with reordering the result
  // This is a function provided by the react-beautiful-dnd example app:
  // https://codesandbox.io/s/k260nyxq9v?file=/index.js
  // Essentially it:
  // - mutates the existing list to remove the moved item
  // - mutates the list again to place it at the idex it was moved to
  // - then returns a new array where the `order` is redetermined to reflect the changes
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, index) => ({
      ...item,
      order: index + 1,
    }));
  };

  const descColClasses = useMemo(() => {
    if (allowSalesTaxExemptItems) {
      return "col-7";
    }

    if (includesSalesTax && !hideGstOnMobile) {
      return "col-6";
    }

    return "col-7 col-sm-8";
  }, [allowSalesTaxExemptItems, includesSalesTax, hideGstOnMobile]);

  return (
    <section className="line-items">
      <TableHeader
        descColClasses={descColClasses}
        includesSalesTax={includesSalesTax}
        hideGstOnMobile={hideGstOnMobile}
        salesTaxName={salesTaxName}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <AnimatePresence initial={false}>
                {lineItems
                  .filter((item) => !item._destroy)
                  .map((item, index) => (
                    <LineItem
                      {...{ item, index, lineItemsAttributesName }}
                      key={item.sortID}
                      handleRemoveItem={removeItem}
                      descColClasses={descColClasses}
                      hideGstOnMobile={hideGstOnMobile}
                    />
                  ))}
              </AnimatePresence>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {/* Outside from the main LineItems output, a hidden input is created for each persisted removed lineItem
            so it can be destroyed on the server */}
        {removedLineItems.map(
          (itm) =>
            itm.id && (
              <input
                key={`removed-item-${itm.id}`}
                type="hidden"
                name={`${itm.model}[${itm.model}_items_to_destroy_ids][]`}
                value={itm.id}
              />
            ),
        )}
        <div className="tw-flex tw-flex-row-reverse md:tw-flex-none tw-mt-2">
          <Button
            classes="tw-max-w-fit"
            variant="link"
            iconType="PlusIcon"
            iconEnd
            onClick={() => addNewItem()}
            dataTrackClick={{ eventName: `${model}_line_item_add_line` }}
          >
            Add line
          </Button>
        </div>
      </DragDropContext>
    </section>
  );
};

// Component to represent the header for the lineItems table
// This is mainly static, but may change in width if the user is allowed to toggle sales tax
export const TableHeader = ({
  descColClasses,
  includesSalesTax,
  hideGstOnMobile,
  salesTaxName,
}) => (
  <header
    className="d-flex tw-py-2"
    style={{ borderBottom: "2px solid #dee2e6" }}
  >
    <div className={`${descColClasses} pl-0`}>
      <span className="tw-font-medium">Service</span>
    </div>
    <div className="col-1 pl-0">
      <span className="tw-font-medium">Qty</span>
    </div>
    <div className="col-2">
      <span className="tw-font-medium">
        Price{" "}
        <span className="tw-text-xs">{`(excl. ${salesTaxName ?? ""})`}</span>
      </span>
    </div>
    {includesSalesTax && !hideGstOnMobile && (
      <div className="col-2 pr-0">
        <strong className="hidden-sm-down">{salesTaxName ?? ""}</strong>
      </div>
    )}
  </header>
);

// Represents the UI for a single LineItem
const LineItem = ({
  item,
  index,
  handleRemoveItem,
  lineItemsAttributesName,
  descColClasses,
  hideGstOnMobile,
}) => {
  // Returns values for a shadow to a lineItem if it is being dragged
  // these values get applied as styles to the lineItem
  const getItemStyle = (isDragging, draggableStyle) => ({
    background: "white",
    boxShadow: isDragging ? "0 0 10px 5px rgba(0, 0, 0, 0.05)" : "",
    ...draggableStyle,
  });

  return (
    <Draggable key={item.sortID} draggableId={item.sortID} index={index}>
      {(provided, snapshot) => (
        <motion.div
          key={`line-item-${item.sortID}`}
          className="d-flex pt-1 align-items-center container px-0 invoice-line-item-row"
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <LineItemContent
            descColClasses={descColClasses}
            hideGstOnMobile={hideGstOnMobile}
            {...{ item, handleRemoveItem, lineItemsAttributesName }}
          />
        </motion.div>
      )}
    </Draggable>
  );
};

// While the `LineItem` component mainly represents the UI for a single LineItem,
// this component handles the internal logic for a single LineItem.
// The state for a LineItem is determined here before publishing the changes to context
const LineItemContent = ({
  item,
  handleRemoveItem,
  lineItemsAttributesName,
  descColClasses,
  hideGstOnMobile,
}) => {
  // Form value state
  const [serviceName, setServiceName] = useState(item.updated_name || "");
  const [quantity, setQuantity] = useState(item.quantity);
  const [itemIncludesSalesTax, setItemIncludesSalesTax] = useState(
    item.includes_sales_tax,
  );
  // Since the price of a line item is saved as either `service_price` or `price` for
  // Invoices and Quotes, respectively, to set the initial state we need to first need to check
  // which one exists and then set it to that value. If they are both null (i.e: a blank line)
  // then initialise the price as an empty string
  const [price, setPrice] = useState(() => {
    if (
      !quantity &&
      item.service_price &&
      item.service_price.length &&
      item.price &&
      item.price.length
    ) {
      return "";
    }

    if (Number.isFinite(item.service_price)) {
      return item.service_price;
    }

    if (Number.isFinite(item.price)) {
      return item.price;
    }

    return "";
  });

  const handleIncludesSalesTaxChange = (value) => {
    const { modelId, id, model } = item;

    setItemIncludesSalesTax(value);
    window.analytics.track(`${model}_line_item_gst_toggled`, {
      feature_code: "GST on Services",
      includes_gst: value,
      invoice_id: modelId || `new_draft_${model}`,
      line_item_id: id || "new_line_item",
    });
  };

  // Get the relevant values from context
  const {
    updateListItem,
    salesTaxRate,
    formNameFor,
    otherProps,
    allowSalesTaxExemptItems,
  } = useInvoiceQuoteContext();
  const { services } = otherProps;

  // Every time any of the inputs on the LineItem change (i.e: the description, quantity, price, or GST-expemptness),
  // Make sure the values are formatted correctly, and publish the changes to the version of the item that lives in context
  useEffect(() => {
    // Make sure numbers are parsed and rounded appropriately.
    // If value exists, make parse it as a number so it can be rounded. If it fails parsing and turns into `NaN`, fallback to 0
    // If a value doesn't exist, just save it as an empty string
    const priceAsNumber = price ? round2Dp(parseFloat(price) || 0) : "";
    const quantityAsNumber = quantity
      ? round2Dp(parseFloat(quantity) || 0)
      : "";
    const salesTax = itemIncludesSalesTax
      ? round2Dp(priceAsNumber * salesTaxRate * quantityAsNumber) || 0
      : 0;

    // Contstruct a new Item with all the correct parsed and formatted values
    const newItem = {
      ...item,
      updated_name: serviceName,
      service_price: priceAsNumber,
      quantity: quantityAsNumber,
      total: round2Dp(priceAsNumber * quantityAsNumber),
      sales_tax: salesTax,
    };

    // Perform a deep comparison to make sure that somehow the newItem and existing one are not the same
    // then update the item in context if they are at all different
    if (!isEqual(newItem, item)) {
      updateListItem(newItem);
    }
  }, [serviceName, quantity, price, itemIncludesSalesTax]);

  // Handles setting the internal quantity state value
  // It strips out the "," from number values
  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value.replace(/\,/g, ""));
  };

  // Handles setting the internal price state value
  // It strips out the "," from number values
  const handlePriceChange = ({ target }) => {
    setPrice(target.value.replace(/\,/g, ""));
  };

  // Handles setting the internal serviceName value, as well as the price if required
  // This gets fired when a dropdown option from Typedown is selected, NOT whenever
  // the input is typed in (see onType handler for Typedown for this). Typedown will
  // return a serviceId, which we lookup in the `services` value in context to get the name
  // and default price. We then update the price and serviceName which these values
  const handleServiceNameChange = (id) => {
    const selectedItem = services.find(([_, serviceId]) => serviceId === id);
    if (selectedItem) {
      const servicePrice = selectedItem[selectedItem.length - 1]["data-price"];
      const selectedServiceName = selectedItem[0];

      setPrice(servicePrice);
      setServiceName(selectedServiceName);
    }
  };

  const handleDiscountChange = (item) => {
    const currentPrice = item.price || item.service_price;

    if (currentPrice !== "" && !isNaN(currentPrice)) {
      const applied = parseFloat(currentPrice) * -1.0;

      setPrice(applied.toFixed(2));
    }
  };

  // Shared input config for all inputs for the LineItem, and
  // the name for sales tax in the current jurisdiction
  const nestedFormDetails = {
    nestedAtributeFor: lineItemsAttributesName,
    index: item.order - 1,
  };
  const salesTaxName = I18n.t("sales_tax.short_name");

  // These are the actions that are available for the Ellipses component (shown on smaller devices)
  // The text value for toggling sales tax will change between "remove" and "add" based on whether the item includes sales tax or not,
  // but we only show this option to the user if they are allowed to toggle this setting
  const ellipsesActions = [
    {
      text: itemIncludesSalesTax
        ? `Remove ${salesTaxName}`
        : `Add ${salesTaxName}`,
      onClick: () => setItemIncludesSalesTax(!itemIncludesSalesTax),
      actionName: "gst",
    },
    {
      text:
        price === "" || price >= 0 ? "Apply as discount" : "Remove discount",
      onClick: () => handleDiscountChange(item),
      actionName: "discount",
    },
    {
      text: "Delete",
      onClick: () => handleRemoveItem(item),
      trackClick: { eventName: `${item.model}_line_item_delete`, data: {description: item.updated_name, price: item.service_price, quantity: item.quantity} },
      actionName: "delete",
    },
  ].filter(({ actionName }) => {
    if (
      actionName === "delete" ||
      (allowSalesTaxExemptItems && actionName === "gst") ||
      (isMobile && actionName === "discount")
    ) {
      return true;
    }

    return false;
  });

  return (
    <div className="m-0 row container p-0 w-100">
      <div
        className={`${descColClasses} d-flex md-form align-items-start m-0 pl-0`}
      >
        <Icon
          type="actions/order"
          label="Reorder this line item"
          className="mt-05"
        />
        <div className="pl-3 w-100">
          <Typedown
            dropdownOptions={services}
            label="Invoice item name"
            labelIsHidden
            iconIsHidden
            fireChangeOnLoad={false}
            allowFromOutsideOptions
            useInputValueForSubmission
            asTextarea
            inputProps={{
              name: formNameFor("updated_name", nestedFormDetails),
              type: "text",
              value:
                services.find((service) => service[0] === serviceName) ||
                serviceName,
              onChange: (id) => handleServiceNameChange(id),
              onType: (event) => setServiceName(event.target.value),
            }}
          />
        </div>
      </div>
      <div className="col-1 px-0 invoice-quantity-col d-flex align-items-end">
        <NumberInput
          id={formNameFor("quantity", nestedFormDetails)}
          name={formNameFor("quantity", nestedFormDetails)}
          className="form-control text-right mb-0"
          value={quantity}
          onChange={handleQuantityChange}
          inputMode="decimal"
          options={{
            numeral: true,
          }}
          autoComplete="off"
        />
        <label
          htmlFor={formNameFor("quantity", nestedFormDetails)}
          className="visually-hidden"
        >
          Quantity
        </label>
      </div>
      <div className="col-2 pr-0 invoice-price-col d-flex align-items-end">
        <CurrencyInput
          inputProps={{
            name: formNameFor("service_price", nestedFormDetails),
            value: price,
            onChange: handlePriceChange,
            className: "text-right mb-0",
            inputMode: "decimal",
            autoComplete: "off",
          }}
          label="Price"
          labelIsHidden
          blankDefault
        />
      </div>
      {allowSalesTaxExemptItems || isMobile ? (
        <Ellipses
          actions={ellipsesActions}
          classes="col-1 tw-pt-2 invoice-actions-col"
        >
          <InlineControls
            hideGstOnMobile={hideGstOnMobile}
            handleIncludesSalesTaxChange={handleIncludesSalesTaxChange}
            {...{
              item,
              nestedFormDetails,
              itemIncludesSalesTax,
              handleRemoveItem,
              formNameFor,
              allowSalesTaxExemptItems,
            }}
          />
        </Ellipses>
      ) : (
        <InlineControls
          hideGstOnMobile={hideGstOnMobile}
          {...{
            item,
            nestedFormDetails,
            itemIncludesSalesTax,
            setItemIncludesSalesTax,
            handleRemoveItem,
            formNameFor,
          }}
        />
      )}
      <input
        type="hidden"
        name={formNameFor("quantity", nestedFormDetails)}
        value={item.quantity}
      />
      {item.id && (
        <input
          type="hidden"
          name={formNameFor("id", nestedFormDetails)}
          value={item.id}
        />
      )}
      <input
        type="hidden"
        name={formNameFor("service_id", nestedFormDetails)}
        value={item.service_id}
      />
      <input
        type="hidden"
        name={formNameFor("service_category", nestedFormDetails)}
        value={item.category}
      />
      <input
        type="hidden"
        name={formNameFor("sales_tax", nestedFormDetails)}
        value={item.sales_tax}
      />
      <input
        type="hidden"
        name={formNameFor("order", nestedFormDetails)}
        value={item.order}
      />
    </div>
  );
};

// This component handles the controls that live next to the inputs of a lineItem
// In all cases, there will be a icon button to delete the item. But if the user has
// specified that they need to, they have an option to toggle sales tax on or off for
// the given lineItem.
// The state and handlers for these controls are specified in the LineItemContent component
const InlineControls = ({
  item,
  nestedFormDetails,
  handleRemoveItem,
  itemIncludesSalesTax,
  handleIncludesSalesTaxChange,
  formNameFor,
  allowSalesTaxExemptItems,
  hideGstOnMobile,
}) => {
  const { includesSalesTax } = useInvoiceQuoteContext();

  const deleteAction = () => (
    <div className="col-1 pr-0 d-flex align-items-start">
      <Icon
        type="actions/delete"
        label="Delete line item"
        className="mt-05"
        asButton
        onClick={() => handleRemoveItem(item)}
        data-track-click={`{ "eventName": "${item.model}_line_item_delete", "data": {"description": "${item.updated_name}", "price": "${item.service_price}", "quantity": "${item.quantity}" }}`}
      />
    </div>
  );

  if (includesSalesTax) {
    return (
      <>
        {allowSalesTaxExemptItems && (
          <div className="col-1">
            <Toggle
              inputProps={{
                value: Number(itemIncludesSalesTax),
                name: formNameFor("includes_sales_tax", nestedFormDetails),
                onChange: () =>
                  handleIncludesSalesTaxChange(!itemIncludesSalesTax),
              }}
              label="Includes GST"
              labelIsHidden
              className="mt-1"
            />
          </div>
        )}
        {!allowSalesTaxExemptItems && !hideGstOnMobile && (
          <div className="col-2 pr-0 d-flex align-items-end">
            <CurrencyInput
              inputProps={{
                name: formNameFor("sales_tax", nestedFormDetails),
                value: item.sales_tax,
                className: "text-right mb-0",
                inputMode: "decimal",
                readOnly: true,
              }}
              label="sales_tax"
              labelIsHidden
              blankDefault
            />
          </div>
        )}
        {deleteAction()}
      </>
    );
  }

  return deleteAction();
};

export default LineItems;
