import React from "react";
import Icon from "../_atoms/icons/icon/Icon";
import EmptyStateModule from "./EmptyStateModule";
import I18n from "../../utilities/translations";

type PayLineType = {
  id: number;
  name: string;
  paidDate: string;
  amount: string;
};

interface iRecentPayments {
  emptyStateImageSrc: string;
  payLines?: PayLineType[];
}

const scope = { scope: "home.index.cards.empty_state" };

const RecentPayments = ({
  emptyStateImageSrc,
  payLines,
}: iRecentPayments) => {
  if (payLines.length > 0) {
    return (
      <ul className="tw-divide-y tw-divide-gray-100 tw-mb-0">
        {payLines.map((payLine) => {
          const {
            id,
            amount,
            paidDate,
            name,
          } = payLine;

          return (
            <li className="hover:tw-bg-gray-50" key={`pay-line-item-${id}`}>
              <a
                href={Routes.pay_line_path(id || 0)}
                data-track-click='{ "eventName": "dashboard_recent_payments_item_click" }'
                className="tw-flex tw-justify-between tw-gap-x-6 tw-py-2 tw-items-center"
              >
                <div className="tw-flex tw-min-w-0 tw-gap-x-4">
                  <div className="tw-min-w-0 tw-flex-auto">
                    <p className="tw-text-base tw-font-medium tw-leading-6 tw-text-gray-900 tw-mb-0">
                      <span className="tw-text-ellipsis tw-overflow-hidden">{name}</span>
                    </p>
                    <p className="tw-truncate tw-text-xs tw-leading-5 tw-text-gray-500 tw-mb-0">{paidDate}</p>
                  </div>
                </div>
                <div className="tw-flex tw-items-center">
                  <p className="tw-text-base tw-leading-6 tw-text-gray-900 tw-mb-0 tw-mr-2 tw-font-semibold">{amount}</p>
                  <Icon type="ChevronRightIcon" size="base" />
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <EmptyStateModule
      title={I18n.t("payslips_title", scope)}
      subtext={I18n.t("payslips_subtext", scope)}
      hideButton={true}
      emptyStateImageSrc={emptyStateImageSrc}
      altImgText="Stack of coins and notes"
    />
  );
}

export default RecentPayments;
