import { get, putJson } from "./config/fetch.api";

type getExpensesGraphDataType = {
  url: string;
  yearFilter: string;
}

type updateDashboardSettingsType = {
  items: string[];
}

export const getExpensesGraphData = async ({ url, yearFilter }: getExpensesGraphDataType) =>
  get(`${url}${yearFilter ? `?year_filter=${yearFilter}` : ""}`);

export const getDashboardSettings = async () =>
  get(Routes.api_dashboard_modules_path());

export const updateDashboardSettings = async ({ items }: updateDashboardSettingsType) => (
  putJson(Routes.api_dashboard_modules_path(), JSON.stringify({ module_list: items })));

