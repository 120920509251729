import React, { useEffect, useState } from "react";
import * as Collapsible from "@radix-ui/react-collapsible";
import Alert from "../../_molecules/alert/Alert";
import I18n from "../../../utilities/translations";
import InputPrice from "../../_atoms/input/InputPrice";
import { useInvoiceQuoteContext } from "../../invoice_quote/InvoiceQuoteContext";
import Switch from "../../_atoms/switch/Switch";

interface iDepositFields {
  isAlertDismissed: boolean;
  deposit: number;
  setDeposit: (value: number) => void;
}

const DepositFields = ({
  isAlertDismissed,
  deposit,
  setDeposit,
}: iDepositFields) => {
  const { currencyOptions } = useInvoiceQuoteContext();
  const [showDeposit, setShowDeposit] = useState(Boolean(deposit));

  const isFirstRender = React.useRef(true); // TODO: Remove once we set state properly.

  useEffect(() => {
    if (isFirstRender.current && deposit) {
      setShowDeposit(Boolean(deposit));
      isFirstRender.current = false;
    }
  }, [deposit]);

  const handleToggle = () => {
    setShowDeposit(!showDeposit);
    if (showDeposit) {
      setDeposit(0);
    }
  };

  return (
    <div>
      <Switch
        id="includes-deposit"
        label="This includes an untaxed portion to be used for the purchase of goods or materials"
        checked={showDeposit}
        onChange={handleToggle}
      />
      <Collapsible.Root open={showDeposit}>
        <Collapsible.Content className="data-[state=closed]:tw-animate-fadeOutUp data-[state=open]:tw-animate-fadeInDown tw-py-4 sm:tw-pl-12">
          {!isAlertDismissed && (
            <Alert>
              {I18n.t("invoices.form.untaxed_portion_warning_message")}{" "}
              <a
                href={I18n.t("invoices.form.untaxed_portion_warning_link")}
                target="_blank"
                rel="noreferrer"
              >
                Learn more
              </a>
            </Alert>
          )}
          <div className="tw-mb-4">
            <InputPrice
              label="Untaxed amount required"
              currencySign={currencyOptions.symbol}
              name="invoice[deposit]"
              value={deposit}
              onChange={setDeposit}
            />
          </div>
        </Collapsible.Content>
      </Collapsible.Root>
    </div>
  );
};

export default DepositFields;
